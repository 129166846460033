import AuthHelper from 'helpers/auth_helper';
import Settings from 'helpers/settings';

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const getFinalURL = (url, params = {}) => {
  let finalUrl = url;
  if (!url.startsWith('http')) {
    finalUrl = Settings.SWIPECAST_BACKEND + url;
  }

  const query = new URLSearchParams(params).toString();
  if (query === '') return finalUrl;
  return `${finalUrl}?${query}`;
};

/** @deprecated v1 - seems like it does not handle errors. for new endpoints use v2 instead */
export const requestV1 = (url, method = 'GET', body = null) => {
  const finalURL = getFinalURL(url);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthHelper.currentAuthToken()}`,
    credentials: 'include'
  };

  return (
    fetch(finalURL, { method, body, headers })
      .then(handleErrors)
      .then(res => res.json())
      // eslint-disable-next-line
      .catch(error => console.log(error))
  );
};

export const requestV2 = async (
  url,
  { method = 'GET', body = null, params = {}, pureResponse = false }
) => {
  const finalURL = getFinalURL(url, params);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthHelper.currentAuthToken()}`,
    credentials: 'include'
  };

  const response = await fetch(finalURL, {
    method,
    body: body ? JSON.stringify(body) : null,
    headers
  });
  if (pureResponse) return await response.json();

  if (!response.ok) {
    let errorMessage;
    try {
      const { error, message } = await response.json();
      errorMessage = error || message;
    } catch {
      errorMessage = response.statusText;
    }
    throw Error(errorMessage);
  }

  return response.json();
};
