import AuthHelper from 'helpers/auth_helper.js';
import SCTrack from 'classes/sc_track';

const $ = window.jQuery;

const conversationHasAdmin = conversation =>
  conversation.participants.filter(participant => participant.admin).length > 0;

const intentRoleInConversation = conversation => {
  const amParticipant = conversation.participants.find(p => p.swipecast_user_id === AuthHelper.logged_in_profileID);
  if (amParticipant.client) return 'client';
  return 'talent';

  // const clients = conversation.participants.filter(
  //   participant => participant.client
  // );
  // if (clients.length === 0 || clients.length === 2) {
  //   return 'neutral';
  // }
  // if (clients[0].swipecast_user_id === AuthHelper.logged_in_profileID) {
  //   return 'client';
  // }
  // return 'talent';
};

const containsDisintermediation = msg => {
  const rePhone = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?([.,])?$/;
  const reEmail = /\A[^@\s]+@[^@\s]+\z/;
  const message = msg.toLowerCase();

  const testAgainst = [
    /^.+@/,
    'agent',
    'agency',
    'booker',
    'call',
    'cash',
    'email',
    'meet',
    'number',
    'phone',
    'zoom',
    'hangout',
    'sheet',
    'venmo',
    'paypal',
    rePhone,
    reEmail
  ];

  const contains = testAgainst.filter(condition => RegExp(condition).test(message.toLowerCase())).length > 0;
  return contains;
};

const disintermediationLabel = (conversation, message, type) => {
  if (type === 'text' && containsDisintermediation(message) && !conversationHasAdmin(conversation)) {
    return intentRoleInConversation(conversation);
  }
  return 'none';
};

// prettier-ignore
const emojiSet = [
  '😀', '😬', '😁', '😂', '😃', '😄', '😅', '😆', '😇', '😉', '😊', '🙂', '🙃', '😋', '😌', '😍', '😘', '😗', '😙', '😚', '😜', '😝', '😛', '🤑', '🤓', '😎', '🤗', '😏', '😶', '😐', '😑', '😒', '🙄', '🤔', '😳', '😞', '😟', '😠', '😡', '😔', '😕', '🙁', '😣', '😖', '😫', '😩', '😤', '😮', '😱', '😨', '😰', '😯', '😦', '😧', '😢', '😥', '😪', '😓', '😭', '😵', '😲', '🤐', '😷', '🤒', '🤕', '😴', '💤', '💩', '😈', '👿', '👹', '👺', '💀', '👻', '👽', '🤖', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙌', '👏', '👋', '👍', '👊', '✊', '👌', '✋', '💪', '🙏', '☝️', '👆', '👇', '👈', '👉', '🖕', '🤘', '🖖', '✍️', '💅', '👄', '👅', '👂', '👃', '👁', '👀', '👤', '🗣', '👶', '👦', '👧', '👨', '👩', '👱', '👴', '👵', '👲', '👳', '👮', '👷', '💂', '🕵', '🎅', '👼', '👸', '👰', '🚶', '🏃', '💃', '👯', '👫', '👬', '👭', '🙇', '💁', '🙅', '🙆', '🙋', '🙎', '🙍', '💇', '💆', '💑', '💏', '👪', '👚', '👕', '👖', '👔', '👗', '👙', '👘', '💄', '💋', '👣', '👠', '👡', '👢', '👞', '👟', '👒', '🎩', '⛑', '🎓', '👑', '🎒', '👝', '👛', '👜', '💼', '👓', '🕶', '💍', '🌂',
];

const ChatHelpers = {
  emojiSet,
  isEmoji: msg => !Array.from(msg).some(char => !emojiSet.includes(char)),
  // same for prod and staging
  assistantSwipecastUserId: '6090dfe5-3176-4b9d-8a97-31bcd80b356c',
  scrubbedMessage: message => {
    return message;
    // const cappedMessage = message.substring(0, 10000);
    // const rePhoneNumber = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    // const reEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    // return cappedMessage
    //   .replace(rePhoneNumber, '[Phone Number Hidden]')
    //   .replace(reEmail, '[Email Hidden]');
  },
  conversationHasAdmin,
  containsDisintermediation,
  intentRoleInConversation,
  disintermediationLabel,
  loadConversations: (starting_after, limit, callback) => {
    let params = { limit: limit };
    if (starting_after !== 'initial') {
      params['starting_after'] = starting_after;
    }
    $.ajax({
      url: '/conversation',
      method: 'GET',
      data: params,
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {},
      success: (data, textStatus, jqXHR) => {
        callback(data.conversations);
      }
    });
  },

  readConversation: (conversation_id, callback) => {
    $.ajax({
      url: '/conversation/' + conversation_id + '/read_conversation',
      method: 'POST',
      data: {},
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {},
      success: (data, textStatus, jqXHR) => {
        if (callback) callback(data);
      }
    });
  },

  searchConversations: (search_term, callback) => {
    $.ajax({
      url: '/conversation/search/' + search_term,
      method: 'GET',
      data: {},
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {},
      success: (data, textStatus, jqXHR) => {
        callback(data.conversations);
      }
    });
  },

  loadConversationWithMessages: (conversation_id, starting_after, limit, callback) => {
    let params = { limit: limit };
    if (starting_after !== 'initial') {
      params['starting_after'] = starting_after;
    }
    $.ajax({
      url: '/conversation/' + conversation_id,
      method: 'GET',
      data: params,
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {},
      success: (data, textStatus, jqXHR) => {
        callback(data.conversation);
      }
    });
  },

  deleteMessage(messageID, onSuccess, onError) {
    $.ajax({
      url: `/v2/messages/${messageID}`,
      method: 'DELETE',
      dataType: 'JSON',
      error: onError,
      success: (data, textStatus, jqXHR) => {
        if (onSuccess) onSuccess(data, textStatus, jqXHR);
      }
    });
  },

  async deleteMessageAsync(messageID) {
    return new Promise((resolve, reject) => this.deleteMessage(messageID, resolve, reject));
  },

  sendMessage: (conversation, message, type, metadata, callback, video, onError) => {
    const data = {
      video,
      message,
      metadata,
      message_type: type
    };
    if (!conversation) return console.error('Conversation is empty');
    Object.keys(data).forEach(key => {
      if ([undefined, null, ''].includes(data[key])) delete data[key];
    });

    $.ajax({
      url: '/conversation/' + conversation.id + '/send_message',
      method: 'POST',
      data,
      dataType: 'JSON',
      error: onError,
      success: (data, textStatus, jqXHR) => {
        if (callback) callback(data);
        SCTrack.all('Send Message', {
          disintermediation_party: disintermediationLabel(conversation, message, type)
        });
      }
    });
  },

  createConversation: (swipecast_user_id, callback) => {
    $.ajax({
      url: '/conversation',
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        swipecast_user_ids: [AuthHelper.logged_in_profileID, swipecast_user_id],
        group: false,
        external: true
      }),
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {
        console.log(jqXHR);
      },
      success: (data, textStatus, jqXHR) => {
        callback(data.conversation);
      }
    });
  },

  loadConversationGivenUserId: (swipecast_user_id, callback, no_conversation_callback) => {
    $.ajax({
      url: '/conversation_with/' + swipecast_user_id,
      method: 'GET',
      data: {},
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status === 404) {
          no_conversation_callback();
        }
      },
      success: (data, textStatus, jqXHR) => {
        callback(data.connection);
      }
    });
  }
};

export default ChatHelpers;
