import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import GoogleButton from 'components/complex/google_sso/google_sso';
import SignUpOrSection from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/components/or_section';
import { Wrapper, PrimaryButton, GuestButton, InnerWrapper } from './styled';
import { routes } from 'helpers/router_helper';
import { ModalContext, RootContext } from 'contexts/contexts';
import CloseButton from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/close_button';
import Error from 'components/reusable/error';
import useOauthSignup from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/hooks/use_oauth_signup';
import Link from 'components/reusable/link';
import SC_GA4, { actions } from 'classes/sc_ga4';
import { modalEnum } from 'components/modals/v2/modal';
import ApplicationHelper from 'helpers/application_helper';
import Flex from 'components/reusable/flex';

const SignUpModalContentV3 = ({ onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const modalContext = useContext(ModalContext);
  const rootContext = useContext(RootContext);
  const onLogin = useCallback(() => modalContext.onOpenModal(modalEnum.signIn));
  const fontSize = ApplicationHelper.isMobile ? 12 : 14;

  const handleContinueWithEmail = useCallback(() => {
    SC_GA4.sendMetric(actions.userCreateFunnel.emailContinue);
    modalContext.onOpenModal(modalEnum.signUpByEmail);
  }, [modalContext.onOpenModal]);

  const onOauthSignUp = useOauthSignup({
    dispatch,
    modalContext,
    rootContext,
    setError
  });

  useEffect(() => {
    SC_GA4.sendMetric(actions.userCreateFunnel.init);
  }, []);

  return (
    <Wrapper>
      <CloseButton onClick={onClose} />

      <InnerWrapper>
        <Text
          textAlign="center"
          size={24}
          weight={500}
          color={colors.gray[700]}
        >
          SIGN UP
        </Text>

        <Indent top={40} />
        <GoogleButton
          label="Sign Up with Google"
          onFailure={setError}
          onSuccess={onOauthSignUp}
        />

        <Indent top={24} />

        <PrimaryButton onClick={handleContinueWithEmail}>
          <Text color={colors.light} size={16} weight={500}>
            Continue with Email
          </Text>
        </PrimaryButton>

        <Indent top={8} />
        <Error textAlign="center">{error}</Error>
        <Indent top={8} />

        <Text textAlign="center" size={fontSize}>
          By signing up, I agree to the{' '}
          <Link
            size={fontSize}
            color={colors.primary[500]}
            href={routes.about.terms.redirectUrl()}
            target="_blank"
          >
            Terms and Conditions
          </Link>
          , confirm I have reviewed the{' '}
          <Link
            size={fontSize}
            color={colors.primary[500]}
            href={routes.about.policy.redirectUrl()}
            target="_blank"
          >
            Privacy Policy
          </Link>{' '}
          and verify that I am over 18 years old.
        </Text>

        <Indent top={40} />
        <SignUpOrSection />
        <Indent top={36} />

        <GuestButton onClick={onLogin}>
          <Text color={colors.gray[600]} size={16} weight={500}>
            Log In
          </Text>
        </GuestButton>

        <Indent top={16} />
        <Flex>
          <Text size={fontSize}>
            Have a job to post right now?{' '}
            <Link
              onClick={onClose}
              to={routes.guestSignUp.redirectUrl()}
              size={fontSize}
            >
              Click here
            </Link>
          </Text>
        </Flex>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SignUpModalContentV3;
