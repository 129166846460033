import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Button from 'components/reusable/button';
import Text from 'components/reusable/text';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

const StyledButton = styled(Button)`
  width: 160px;
  @media (max-width: 768px) {
    width: auto;
    flex: 1;
  }
`;

const Wrapper = styled(Flex)`
  margin-top: auto;
`;

const ButtonsToolbar = ({ onBack, isSubmitDisabled, submitLabel = 'Next' }) => {
  return (
    <Wrapper gap={16}>
      {onBack && (
        <StyledButton
          onClick={onBack}
          height={40}
          color={colors.gray[600]}
          bgColor={colors.gray[100]}
        >
          Back
        </StyledButton>
      )}

      <StyledButton
        type="primary"
        semanticType="submit"
        height={40}
        isDisabled={isSubmitDisabled}
      >
        <Text color={colors.light} weight={500}>
          {submitLabel}
        </Text>
        {'     '}
        {svgIcons.forwardArrow(colors.light)}
      </StyledButton>
    </Wrapper>
  );
};

export default ButtonsToolbar;
