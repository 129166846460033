import { useSelector } from 'react-redux';

const sharedPhotosSelector = state => state.sharedPhotosV2;
const sharedVideosSelector = state => state.sharedVideosV2;
const sharedFilesSelector = state => state.sharedFilesV2;
const sharedLinksSelector = state => state.sharedLinksV2;

export const useSharedPhotosSelector = shallowEqual => useSelector(sharedPhotosSelector, shallowEqual);
export const useSharedVideosSelector = shallowEqual => useSelector(sharedVideosSelector, shallowEqual);
export const useSharedFilesSelector = shallowEqual => useSelector(sharedFilesSelector, shallowEqual);
export const useSharedLinksSelector = shallowEqual => useSelector(sharedLinksSelector, shallowEqual);
