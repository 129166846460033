import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthHelper from 'helpers/auth_helper';
import { getNotifications, getUnreadNotificationsCount } from 'store/reducers';
import {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess
} from 'store/actions';
import NotificationHelpers from 'helpers/notification_helpers';
import { getJobsPath, routes } from 'helpers/router_helper';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import { styleDimensions } from 'helpers/style';
import svgIcons from 'helpers/svgIcons';
import QueryHelpers from 'helpers/query_helpers';

const StyledLink = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: ${colors.light};
`;

const InnerContainer = styled.div`
  position: relative;
`;

const TabBarIcon = ({
  defaultIcon,
  selectedIcon,
  title,
  isActive,
  href = '#',
  onClick
}) => {
  const color = isActive ? colors.gray[700] : colors.gray[300];
  const uri = onClick ? '#' : href;

  return (
    <StyledLink className="TabBarIconContainer" to={uri} onClick={onClick}>
      <InnerContainer>{isActive ? selectedIcon : defaultIcon}</InnerContainer>
      <Text size={10} weight={500} color={color}>
        {title.toUpperCase()}
      </Text>
    </StyledLink>
  );
};

const Container = styled.footer`
  z-index: 200;
  height: ${styleDimensions.mobileBarHeight};
  min-height: ${styleDimensions.mobileBarHeight};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  flex-direction: row;
  box-shadow: 0px -2px 8px rgba(31, 30, 30, 0.08);
`;

class TabBarMobileStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (AuthHelper.logged_in) {
      this.props.fetchNotifications();
      this.props.fetchUnreadNotificationsCount();
    }
  }

  render() {
    const { location } = this.props;

    const explore = {
      id: 'discover',
      title: 'Discover',
      selectedIcon: svgIcons.search(colors.gray[700]),
      defaultIcon: svgIcons.search(colors.gray[300]),
      href: routes.discover.base.redirectUrl(),
      isActive:
        location.pathname === '/' ||
        /\/discover/g.test(location.pathname) ||
        QueryHelpers.getNavActiveness('discover')
    };

    const jobs = {
      id: 'jobs',
      title: 'Jobs',
      selectedIcon: svgIcons.suitCase(colors.gray[700]),
      defaultIcon: svgIcons.suitCase(colors.gray[300]),
      isActive:
        [
          routes.newBookingOfferSummary.redirectUrl(),
          routes.newBookingOffer.redirectUrl(),
          routes.jobs.list.redirectUrl(),
          routes.projectShow.redirectUrl(),
          routes.jobs.my.redirectUrl(),
          routes.createProject.redirectUrl()
        ].includes(location.pathname) ||
        QueryHelpers.getNavActiveness('jobs') ||
        routes.bookingOfferShow.regexp.test(location.pathname),
      href: getJobsPath()
    };

    const messages = {
      id: 'messages',
      title: 'Messages',
      selectedIcon: this.state.message_notifications
        ? svgIcons.chatWithDot(colors.gray[700])
        : svgIcons.chat(colors.gray[700]),
      defaultIcon: this.state.message_notifications
        ? svgIcons.chatWithDot(colors.gray[300])
        : svgIcons.chat(colors.gray[300]),
      isActive: location.pathname.startsWith(routes.chat.redirectUrl()),
      onClick: () => {
        NotificationHelpers.viewNewConversations(() => {
          this.props.history.push(routes.chat.redirectUrl());
        });
      }
    };

    const notifications = {
      id: 'notifications',
      title: 'Alerts',
      selectedIcon: this.props.unreadNotificationsCount.data
        ? svgIcons.bellWithDot(colors.gray[700])
        : svgIcons.bell(colors.gray[700]),
      defaultIcon: this.props.unreadNotificationsCount.data
        ? svgIcons.bellWithDot(colors.gray[300])
        : svgIcons.bell(colors.gray[300]),
      isActive: location.pathname === routes.notifications.redirectUrl(),
      onClick: () => {
        NotificationHelpers.viewNewNotifications(() => {
          this.props.fetchUnreadNotificationsCountSuccess(0);
          this.props.history.push(routes.notifications.redirectUrl());
        });
      }
    };

    const profile = {
      id: 'profile',
      title: 'Profile',
      selectedIcon: svgIcons.user(colors.gray[700]),
      defaultIcon: svgIcons.user(colors.gray[300]),
      href: routes.menu.redirectUrl(),
      isActive: location.pathname === routes.menu.redirectUrl()
    };

    const icons = [explore, jobs, messages, notifications, profile];
    const iconsWaitlisted = [explore, jobs, messages, notifications, profile];

    return (
      <Container>
        {(AuthHelper.logged_in_waitlisted ? iconsWaitlisted : icons).map(
          (item, i) => (
            <TabBarIcon
              key={i}
              isActive={item.isActive}
              defaultIcon={item.defaultIcon}
              selectedIcon={item.selectedIcon}
              title={item.title}
              href={item.href}
              onClick={item.onClick}
            />
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  unreadNotificationsCount: getUnreadNotificationsCount(state)
});

const TabBarMobile = connect(mapStateToProps, {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess
})(withRouter(TabBarMobileStatic));

export default TabBarMobile;
