import React, { useCallback, useState } from 'react';
import { Field, reduxForm } from 'redux-form';

import {
  Wrapper,
  WrapperWithProgressIndent
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Indent from 'components/reusable/indent';
import { StyledForm, StyledField } from './styled';
import Progress from './components/progress';
import ButtonsToolbar from './components/buttons_toolbar';
import CloseButton from './components/close_button';
import Heading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/heading';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import Error from 'components/reusable/error';
import Settings from 'helpers/settings';

const SignUpReferralStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  onBack,
  serverError,
  ...props
}) => {
  const isSubmitDisabled = props.invalid || props.submitting;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Progress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <CloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <Heading label="Got a referral code?" index={index} />
        <Indent top={24} />

        <Field
          name={name}
          component={StyledField}
          placeholder="Referral Code (optional)"
          autoComplete={name}
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />

        <ButtonsToolbar
          onBack={onBack}
          submitLabel="Submit"
          isSubmitDisabled={isSubmitDisabled}
        />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  form: signUpStepsData.referral.form,
  initialValues: {
    [signUpStepsData.referral.field]: ''
  },
  destroyOnUnmount: false
})(SignUpReferralStep);
