import AuthHelper from 'helpers/auth_helper';

//todo: bring to a unified pattern

export const logout = () => ({
  type: 'USER_LOGOUT'
});

export const fetchBoards = userId => ({
  type: 'BOARDS_FETCH_REQUESTED',
  user_id: userId || AuthHelper.logged_in_profileID
});

export const fetchBoardsSuccess = boards => ({
  type: 'BOARDS_FETCH_SUCCEEDED',
  response: boards
});

export const fetchBoardsError = message => ({
  type: 'BOARDS_FETCH_FAILED',
  message
});

export const fetchIndividualJob = id => ({
  type: 'JOB_FETCH_REQUESTED',
  id
});

export const fetchIndividualJobSuccess = job => ({
  type: 'JOB_FETCH_SUCCEEDED',
  response: job
});

export const fetchIndividualJobError = message => ({
  type: 'JOB_FETCH_FAILED',
  message
});

export const fetchReviews = userId => ({
  type: 'REVIEWS_FETCH_REQUESTED',
  user_id: userId
});

export const fetchReviewsSuccess = reviews => ({
  type: 'REVIEWS_FETCH_SUCCEEDED',
  response: reviews
});

export const fetchReviewsError = message => ({
  type: 'REVIEWS_FETCH_FAILED',
  message
});

export const fetchAlbums = userId => ({
  type: 'ALBUMS_FETCH_REQUESTED',
  user_id: userId
});

export const fetchAlbumsSuccess = albums => ({
  type: 'ALBUMS_FETCH_SUCCEEDED',
  response: albums
});

export const fetchAlbumsError = message => ({
  type: 'ALBUMS_FETCH_FAILED',
  message
});

export const fetchNotifications = () => ({
  type: 'NOTIFICATIONS_FETCH_REQUESTED'
});

export const fetchNotificationsSuccess = notifications => ({
  type: 'NOTIFICATIONS_FETCH_SUCCEEDED',
  response: notifications
});

export const fetchNotificationsError = message => ({
  type: 'NOTIFICATIONS_FETCH_FAILED',
  message
});

export const fetchUnreadNotificationsCount = () => ({
  type: 'UNREAD_NOTIFICATIONS_COUNT_FETCH_REQUESTED'
});

export const fetchUnreadNotificationsCountSuccess = count => ({
  type: 'UNREAD_NOTIFICATIONS_COUNT_FETCH_SUCCEEDED',
  response: { unread_count: count }
});

export const fetchUnreadNotificationsCountError = message => ({
  type: 'UNREAD_NOTIFICATIONS_COUNT_FETCH_FAILED',
  response: message
});
