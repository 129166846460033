import React from 'react';

class SolidButton extends React.Component {
  getStyles = () => {
    var backgroundColor = this.props.disabled ? '#777' : 'black';
    var borderColor = 'black';
    var color = 'white';
    var hoverClass = 'hover_opacity';
    return {
      button: {
        cursor: this.props.disabled ? 'not-allowed' : 'pointer',
        textAlign: 'center',
        lineHeight:
          this.props.size === 'medium'
            ? '40px'
            : this.props.size === 'large'
            ? '60px'
            : '30px',
        backgroundColor: backgroundColor,
        border: '1px solid',
        borderColor: borderColor,
        fontFamily: 'Avenir-Heavy,Lato',
        fontWeight: '700',
        fontSize:
          this.props.size === 'medium'
            ? '13px'
            : this.props.size === 'large'
            ? '15px'
            : '12px',
        letterSpacing: '1px',
        height:
          this.props.size === 'medium'
            ? '40px'
            : this.props.size === 'large'
            ? '60px'
            : '30px',
        width: '100%',
        color: color,
        padding: '0px'
      }
    };
  };

  onClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    var styles = this.getStyles();
    return (
      <button
        {...this.props}
        style={
          this.props.style
            ? Object.assign(styles.button, this.props.style)
            : styles.button
        }
        type="submit"
        onClick={this.onClick}
        disabled={this.props.disabled}
      >
        {this.props.title}
      </button>
    );
  }
}

export default SolidButton;
