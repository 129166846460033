import AuthHelper from 'helpers/auth_helper';

export const searchParams = {
  referralCode: 'referral_code',
  modes: {
    embed: 'embed',
    guestOnboarding: 'guest_onboarding'
  },
  publicPosting: {
    looking_for: 'looking_for'
  }
};

export const getJobsPath = () => {
  const user = AuthHelper.currentUser();
  // keep for now
  // if (user?.client || user?.can_be_client) return routes.jobs.my.redirectUrl();
  if (user?.client) return routes.jobs.my.redirectUrl();
  return routes.jobs.list.redirectUrl();
};

const _getUrlWithQuery = (url, searchParams = {}) => {
  if (!searchParams) return url;
  Object.keys(searchParams).forEach(key => {
    const emptyEntries = [null, undefined, ''];
    if (emptyEntries.includes(searchParams[key])) delete searchParams[key];
  });
  const query = new URLSearchParams(searchParams).toString();
  return query === '' ? url : `${url}?${query}`;
};

export const routes = {
  newBookingOffer: {
    path: '/offers/new',
    redirectUrl: () => '/offers/new'
  },
  editBookingOffer: {
    path: '/offers/:swipecast_offer_id/edit',
    redirectUrl: swipecast_offer_id => `/offers/${swipecast_offer_id}/edit`
  },
  newBookingOfferSummary: {
    path: '/offers/summary',
    redirectUrl: () => '/offers/summary'
  },
  bookingOfferShow: {
    path: '/offers/:swipecast_offer_id',
    redirectUrl: (swipecast_offer_id, searchParams) => _getUrlWithQuery(`/offers/${swipecast_offer_id}`, searchParams),
    regexp: /^\/offers\//
  },
  guestSignUp: {
    path: '/guest/sign_up',
    redirectUrl: () => `/guest/sign_up`
  },
  signUpSuccess: {
    path: '/sign_up/success',
    redirectUrl: () => '/sign_up/success'
  },
  guest: {
    path: '/guest',
    redirectUrl: () => '/guest'
  },
  contact: {
    path: '/contact',
    redirectUrl: () => '/contact'
  },
  resetPassword: {
    path: '/reset_password',
    redirectUrl: () => '/reset_password'
  },
  boards: {
    path: '/boards',
    redirectUrl: () => '/boards'
  },
  login: {
    path: '/login',
    redirectUrl: () => '/login'
  },
  // TODO remove additional nesting
  logged_out: {
    landing: {
      path: '/',
      label: 'Landing',
      redirectUrl: () => '/'
    },
    swipecastPro: {
      path: '/swipecast_pro',
      label: 'Swipecast Pro',
      redirectUrl: () => '/swipecast_pro'
    }
  },
  // TODO remove additional nesting
  about: {
    policy: {
      path: '/privacy_policy',
      redirectUrl: () => '/privacy_policy'
    },
    terms: {
      path: '/terms_of_service',
      redirectUrl: () => '/terms_of_service'
    }
  },
  // TODO remove additional nesting
  discover: {
    categories: {
      path: '/discover/categories',
      redirectUrl: () => '/discover/categories'
    },
    base: {
      path: '/',
      label: 'Discover',
      redirectUrl: () => '/'
    }
  },
  // TODO remove additional nesting
  user: {
    me: {
      albums: {
        path: '/me/albums',
        redirectUrl: () => '/me/albums'
      },
      albumView: {
        path: '/me/albums/:album_id',
        redirectUrl: swipecast_album_id => `/me/albums/${swipecast_album_id}`
      }
    }
  },
  userProfile: {
    path: '/(profile/)?:swipecast_user_id/:section?',
    label: 'User profile',
    redirectUrl: (idOrSlug, section, searchParams) => {
      const sectionParam = section ? `/${section}` : '';
      return _getUrlWithQuery(`/profile/${idOrSlug}${sectionParam}`, searchParams);
    }
  },
  // TODO remove additional nesting
  network: {
    base: {
      path: '/network',
      redirectUrl: () => '/network'
    },
    filters: {
      path: '/network/filters',
      redirectUrl: (searchParams = {}) => _getUrlWithQuery('/network/filters', searchParams)
    },
    talents: {
      path: '/network/:category/:city?',
      redirectUrl: category => `/network/${category}`
    },
    input: {
      path: '/network/input',
      redirectUrl: () => '/network/input'
    }
  },
  finishProfile: {
    path: '/finish_profile', // pure url for react router
    redirectUrl: () => '/finish_profile' // for url with query params or route params
  },
  projectShow: {
    path: '/project/:swipecast_project_id',
    redirectUrl: swipecastProjectId => `/project/${swipecastProjectId}`
    // path: '/project/:swipecast_project_id/:section?',
    // redirectUrl: (swipecastProjectId, section) => {
    //   if (!section) return `/project/${swipecastProjectId}`;
    //   return `/project/${swipecastProjectId}/${section}`;
    // }
  },
  createProject: {
    path: '/create_project/:step?',
    bookingUrl: bookee_swipecast_user_id => {
      return `/create_project?profile_type=booking&otherID=${bookee_swipecast_user_id}`;
    },
    redirectUrl: searchParams => _getUrlWithQuery('/create_project', searchParams)
  },
  createProjectSuccess: {
    path: '/create_project/success',
    redirectUrl: () => '/create_project/success'
  },
  // TODO remove additional nesting
  projectEdit: {
    main: {
      path: '/project/:swipecast_project_id/edit',
      redirectUrl: swipecastProjectId => `/project/${swipecastProjectId}/edit`
    },
    title: {
      path: '/project/:swipecast_project_id/edit_title',
      redirectUrl: swipecastProjectId => `/project/${swipecastProjectId}/edit_title`
    },
    picture: {
      path: '/project/:swipecast_project_id/edit_picture',
      redirectUrl: swipecastProjectId => `/project/${swipecastProjectId}/edit_picture`
    }
  },
  createProjectRole: {
    path: '/project/:swipecast_project_id/add_role',
    redirectUrl: swipecastProjectId => `/project/${swipecastProjectId}/add_role`
  },
  deadEnd: {
    path: '/dead_end',
    redirectUrl: () => '/dead_end'
  },
  // TODO remove additional nesting
  jobs: {
    show: {
      // section invoice used for IOS app universal links to navigate to InvoiceScene
      path: '/job(s)?/:swipecast_job_id/(invoice)?',
      redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}`
    },
    edit: {
      main: {
        path: '/job(s)?/:swipecast_job_id/edit',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit`
      },
      rate: {
        path: '/job(s)?/:swipecast_job_id/edit_rate',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit_rate`
      },
      dates: {
        path: '/job(s)?/:swipecast_job_id/edit_dates',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit_dates`
      },
      time: {
        path: '/job(s)?/:swipecast_job_id/edit_time',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit_time`
      },
      location: {
        path: '/job(s)?/:swipecast_job_id/edit_location',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit_location`
      },
      notes: {
        path: '/job(s)?/:swipecast_job_id/edit_notes',
        redirectUrl: swipecastJobId => `/jobs/${swipecastJobId}/edit_notes`
      }
    },
    my: {
      label: 'My Jobs',
      path: '/my_jobs',
      redirectUrl: () => '/my_jobs'
    },
    list: {
      path: '/jobs',
      label: 'Jobs',
      redirectUrl: (searchParams = {}) => _getUrlWithQuery('/jobs', searchParams)
    },
    filters: {
      path: '/jobs/filters',
      redirectUrl: (searchParams = {}) => _getUrlWithQuery('/jobs/filters', searchParams)
    }
  },
  //mobile
  chatConversations: {
    path: '/chat',
    redirectUrl: () => '/chat'
  },
  //mobile
  chatConversation: {
    path: '/chat/:conversation_id',
    redirectUrl: conversation_id => `/chat/${conversation_id}`
  },
  //mobile
  chatSidePanel: {
    path: '/chat/:conversation_id/side',
    redirectUrl: conversation_id => `/chat/${conversation_id}/side`
  },
  //desktop
  chat: {
    path: '/chat/:conversation_id?',
    label: 'Chat',
    redirectUrl: (conversationId = null, searchParams = {}) =>
      conversationId
        ? _getUrlWithQuery(`/chat/${conversationId}`, searchParams)
        : _getUrlWithQuery('/chat', searchParams)
  },
  notifications: {
    path: '/notifications',
    redirectUrl: () => '/notifications'
  },
  menu: {
    path: '/menu',
    redirectUrl: () => '/menu'
  },
  profileEdit: {
    path: '/profile/edit',
    redirectUrl: () => '/profile/edit'
  },
  profileQrShare: {
    path: '/profile/qr_share',
    redirectUrl: () => '/profile/qr_share'
  },
  profileShare: {
    path: '/profile/share',
    redirectUrl: () => '/profile/share'
  },
  profileOnboarding: {
    path: '/profile/onboarding',
    redirectUrl: () => '/profile/onboarding'
  },
  myProfile: {
    path: '/my_profile',
    redirectUrl: () => '/my_profile'
  },
  changePassword: {
    path: '/change_password',
    redirectUrl: () => '/change_password'
  },
  help: {
    path: '/help',
    redirectUrl: () => '/help'
  },
  faq: {
    path: '/help/faq',
    redirectUrl: () => '/help/faq'
  },
  waitlist: {
    path: '/help/waitlist',
    redirectUrl: () => '/help/waitlist'
  },
  helpCallUs: {
    path: '/help/call_us',
    redirectUrl: () => '/help/call_us'
  },
  helpMessageUs: {
    path: '/help/message_us',
    redirectUrl: () => '/help/message_us'
  },
  basicInfo: {
    path: '/basic_info',
    redirectUrl: () => '/basic_info'
  },
  stats: {
    path: '/stats',
    redirectUrl: () => '/stats'
  },
  socials: {
    path: '/socials',
    redirectUrl: () => '/socials'
  },
  bankAccount: {
    path: '/bank_account',
    redirectUrl: () => '/bank_account'
  },
  cardInfo: {
    path: '/card_info',
    redirectUrl: () => '/card_info'
  },
  paymentSettings: {
    path: '/payment_settings',
    redirectUrl: () => '/payment_settings'
  },
  settings: {
    path: '/settings',
    redirectUrl: () => '/settings'
  }
};

class RouterHelper {
  static root = window.location.href.replace(window.location.pathname, '/');

  static image_url = (image_name, size) => {
    if (size === undefined) {
      size = 525;
    }
    return process.env.REACT_APP_SWIPECAST_IMAGE_BACKEND + '/image?fileName=' + image_name + '&size=' + size;
  };

  static image_url_no_size = image_name => {
    return process.env.REACT_APP_SWIPECAST_IMAGE_BACKEND + '/image?fileName=' + image_name;
  };

  static getUrlVars = () => {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = value;
    });
    return vars;
  };

  static getReferralLink = code => `${window.location.origin}?${searchParams.referralCode}=${code}`;

  static getLIBReferralLink = user => {
    if (!user) return '';
    // const origin = 'https://swipecast-staging.netlify.app',
    const { origin } = window.location,
      { swipecast_user_id, referral_code } = user,
      endpoint = routes.userProfile.redirectUrl(swipecast_user_id),
      query = `?${searchParams.referralCode}=${referral_code}-B`;

    return origin + endpoint + query;
  };

  static isLiBLink = () => {
    const regexp = /referral_code=[a-zA-Z]{4,6}\d{2,4}-B/;
    return regexp.test(window.location.search);
  };

  static getHostURL(url) {
    try {
      return new URL(url).hostname;
    } catch {
      const parts = url.split('/');
      return parts.length ? parts.at(-1) : url;
    }
  }
}

export default RouterHelper;
