import { useSelector } from 'react-redux';
import get from 'lodash.get';

import SignUpProfileStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_profile_step';
import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import SignUpIntentionStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_intention_step';
import SignUpLocationStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_location_step';
import SignUpPhoneStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_phone_step';
import SignUpCompanyStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_company_step';
import SignUpWebsiteStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_website_step';
import SignUpIGStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_ig_step';
import SignUpReferralStep from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_referral_step';
import { actions } from 'classes/sc_ga4';
import { useCallback } from 'react';
import { LocalStorage } from 'classes/sc_localstorage';
import Settings from 'helpers/settings';
import AuthHelper from 'helpers/auth_helper';

const profileStep = {
  component: SignUpProfileStep,
  name: signUpStepsData.profile.field,
  event: actions.userCreateFunnel.profile
};
const intentionStep = {
  component: SignUpIntentionStep,
  name: signUpStepsData.intention.field,
  event: actions.userCreateFunnel.intention
};
const locationStep = {
  component: SignUpLocationStep,
  name: signUpStepsData.location.field,
  event: actions.userCreateFunnel.location
};
const phoneStep = {
  component: SignUpPhoneStep,
  name: signUpStepsData.phone.field,
  event: actions.userCreateFunnel.phone
};
const companyStep = {
  component: SignUpCompanyStep,
  name: signUpStepsData.company.field,
  event: actions.userCreateFunnel.company
};
const websiteStep = {
  component: SignUpWebsiteStep,
  name: signUpStepsData.website.field,
  event: actions.userCreateFunnel.website
};
const igStep = {
  component: SignUpIGStep,
  name: signUpStepsData.ig.field,
  event: actions.userCreateFunnel.social
};
const referralStep = {
  component: SignUpReferralStep,
  name: signUpStepsData.referral.field,
  event: actions.userCreateFunnel.referral
};

const buildProfileStep = () => {
  if ((AuthHelper.currentUser()?.profiles || []).length === 0)
    return [profileStep];
  return [];
};

const buildReferralStep = () => {
  return Settings.enableAuthReferralStep && !LocalStorage.fetchReferralCode()
    ? [referralStep]
    : [];
};

const signUpStepsCategory1 = [
  ...buildProfileStep(),
  locationStep,
  phoneStep,
  companyStep,
  websiteStep,
  igStep,
  ...buildReferralStep()
];

const signUpStepsCategory2 = [
  ...buildProfileStep(),
  locationStep,
  phoneStep,
  igStep,
  ...buildReferralStep()
];

const signUpStepsCategory3 = [
  ...buildProfileStep(),
  locationStep,
  phoneStep,
  websiteStep,
  igStep,
  ...buildReferralStep()
];

const signUpStepsCategory4 = [
  ...buildProfileStep(),
  locationStep,
  phoneStep,
  companyStep,
  igStep,
  ...buildReferralStep()
];

const category4Profiles = ['Prop Stylist', 'Casting Director'];
const category3Profiles = ['Photographer', 'Stylist', 'Videographer'];
const category2Profiles = [
  'Female Model',
  'Male Model',
  'Make-up Artist',
  'Hair Stylist',
  'Influencer',
  'Manicurist',
  'Hand / Foot Model',
  'Retoucher'
];
const category1Profiles = [
  'Producer',
  'Other',
  'Rental Studio',
  'Creative Director'
];

const uncertainProfiles = ['Other', 'Photographer'];

const profileFormSelector = state => state.form[signUpStepsData.profile.form];

const useSteps = step => {
  const profileForm = useSelector(profileFormSelector);
  const profile = get(profileForm, `values.${signUpStepsData.profile.field}`);

  const defaultSteps = useCallback(profile => {
    if (category4Profiles.includes(profile)) return signUpStepsCategory4;
    if (category3Profiles.includes(profile)) return signUpStepsCategory3;
    if (category2Profiles.includes(profile)) return signUpStepsCategory2;
    if (category1Profiles.includes(profile)) return signUpStepsCategory1; // to be more explicit

    return signUpStepsCategory1;
  }, []);

  let signUpSteps = defaultSteps(profile);

  if (uncertainProfiles.includes(profile))
    signUpSteps = [
      ...signUpSteps.slice(0, 1),
      intentionStep,
      ...signUpSteps.slice(1)
    ];

  const currentStep = signUpSteps[step];
  const isLastStep = step + 1 >= signUpSteps.length;

  return { signUpSteps, currentStep, isLastStep };
};

export default useSteps;
