import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import {
  PrimaryButton,
  Wrapper,
  StyledReduxField,
  InnerWrapper
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Error from 'components/reusable/error';
import Flex from 'components/reusable/flex';
import CloseButton from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/close_button';
import Link from 'components/reusable/link';

const StyledForm = styled.form`
  width: 100%;
`;

const NameWrapper = styled(Flex)`
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    align-items: stretch;
  }
`;

const SignUpByEmailForm = ({
  onSubmit,
  onClose,
  error,
  onLogin,
  isSubmitDisabled
}) => {
  return (
    <Wrapper>
      <CloseButton onClick={onClose} />

      <InnerWrapper>
        <Text
          textAlign="center"
          size={24}
          weight={500}
          color={colors.gray[700]}
        >
          SIGN UP
        </Text>

        <Indent top={40} />

        <StyledForm onSubmit={onSubmit}>
          <NameWrapper>
            <Field
              component={StyledReduxField}
              name="first_name"
              placeholder="First Name"
              autoComplete="given-name"
            />
            <Field
              component={StyledReduxField}
              name="last_name"
              placeholder="Last Name"
              autoComplete="family-name"
            />
          </NameWrapper>

          <Indent top={24} />

          <Field
            component={StyledReduxField}
            name="email"
            placeholder="Email"
            autoComplete="email"
          />

          <Indent top={24} />

          <Field
            component={StyledReduxField}
            name="password"
            placeholder="Password"
            type="password"
            autoComplete="new-password"
          />

          <Indent top={12}></Indent>
          <Error textAlign="center">{error}</Error>
          <Indent top={32} />

          <PrimaryButton
            semanticType="submit"
            type="primary"
            isDisabled={isSubmitDisabled}
          >
            <Text color={colors.light} size={16} weight={500}>
              Sign Up
            </Text>
          </PrimaryButton>
        </StyledForm>

        <Indent top={24} />

        <Text textAlign="center" size={14}>
          Already have an account?{' '}
          <Link size={14} onClick={onLogin}>
            Log In
          </Link>
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SignUpByEmailForm;
