import React, { useMemo } from 'react';
import styled from 'styled-components';

import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

const Wrapper = styled.span`
  cursor: pointer;
  ${props => props.css}
`;

const Checkbox = ({ value, onChange, version = 1, css, width }) => {
  const truthyIcon = useMemo(() => {
    if (version === 1)
      return svgIcons.checkboxTruthyIconV1(colors.primary[500], { width });
    if (version === 2)
      return svgIcons.checkboxTruthyIconV2(colors.gray[300], { width });
  }, [version]);

  const falsyIcon = useMemo(() => {
    if (version === 1)
      return svgIcons.checkboxFalsyIconV1(colors.gray[300], { width });
    if (version === 2)
      return svgIcons.checkboxFalsyIconV2(colors.gray[300], { width });
  }, [version]);

  const onToggle = () => onChange && onChange(!value);
  return (
    <Wrapper onClick={onToggle} css={css}>
      {value ? truthyIcon : falsyIcon}
    </Wrapper>
  );
};

export default Checkbox;
