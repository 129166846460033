import React, { useLayoutEffect } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch } from 'react-redux';

import {
  Wrapper,
  WrapperWithProgressIndent
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Indent from 'components/reusable/indent';
import { StyledForm, StyledField } from './styled';
import Progress from './components/progress';
import ButtonsToolbar from './components/buttons_toolbar';
import CloseButton from './components/close_button';
import Heading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/heading';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';
import AuthHelper from 'helpers/auth_helper';

const SignUpIGStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  serverError,
  onBack,
  igError,
  ...props
}) => {
  const dispatch = useDispatch();
  const isSubmitDisabled = props.invalid || props.submitting;

  useLayoutEffect(() => {
    const user = AuthHelper.currentUser();
    if (user && user.instagram) {
      dispatch(
        change(
          signUpStepsData.ig.form,
          signUpStepsData.ig.field,
          user.instagram
        )
      );
    }
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Progress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <CloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <Heading label="Connect your Instagram" index={index} />
        <Indent top={24} />

        <Field
          name={name}
          validate={ffHelpers.required}
          component={StyledField}
          error={igError}
          placeholder="@username"
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <ButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  form: signUpStepsData.ig.form,
  initialValues: {
    [signUpStepsData.ig.field]: ''
  },
  destroyOnUnmount: false
})(SignUpIGStep);
