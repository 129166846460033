import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';

import boards from './boards';
import jobs from './jobs';
import reviews from './reviews';
import albums from './albums';
import notifications from './notifications';
import unreadNotificationsCount from './unread_notifications';
import topSlide from './topSlide';
import storage from 'redux-persist/lib/storage';
import jobsV2Reducers from 'store/resources/jobsV2/reducers';
import applicationsV2Reducers from 'store/resources/applicationsV2/reducers';
import sharedVideosV2Reducers from 'store/resources/sharedVideosV2/reducers';
import sharedPhotosV2Reducers from 'store/resources/sharedPhotosV2/reducers';
import sharedLinksV2Reducers from 'store/resources/sharedLinksV2/reducers';
import sharedFilesV2Reducers from 'store/resources/sharedFilesV2/reducers';
import usersV2Reducers from 'store/resources/sharedVideosV2/reducers';
import applicationsMetaV2Reducers from 'store/resources/applicationsMetaV2/reducers';
import { jobReducer } from './job';
import { projectReducer } from './project';

const formConfig = {
  key: 'form',
  storage,
  blacklist: ['signUpByEmail']
};

const appReducer = combineReducers({
  boards,
  jobs,
  reviews,
  albums,
  notifications,
  unreadNotificationsCount,
  topSlide,
  form: persistReducer(formConfig, formReducer),
  job: jobReducer,
  project: projectReducer,

  // v2
  jobsV2: jobsV2Reducers,
  usersV2: usersV2Reducers,
  applicationsV2: applicationsV2Reducers,
  applicationsMetaV2: applicationsMetaV2Reducers,
  sharedLinksV2: sharedLinksV2Reducers,
  sharedFilesV2: sharedFilesV2Reducers,
  sharedVideosV2: sharedVideosV2Reducers,
  sharedPhotosV2: sharedPhotosV2Reducers
});

export default (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:SCStore');
    state = undefined;
  }
  return appReducer(state, action);
};

export const getBoards = (state = []) => state.boards;

export const getReviews = (state = []) => state.reviews;

export const getAlbums = (state = []) => state.albums;

export const getNotifications = (state = []) => state.notifications;

export const getUnreadNotificationsCount = (state = 0) => state.unreadNotificationsCount;
