import React, { useContext, useLayoutEffect, useState } from 'react';

import { ModalContext } from 'contexts/contexts';
import useSteps from 'components/modals/v2/auth/sign_up_additional_details_modal/hooks/use_steps';
import useDestroyOngoingStepsData from 'components/modals/v2/auth/sign_up_additional_details_modal/hooks/use_destroy_ongoing_steps_data';
import useBack from 'components/modals/v2/auth/sign_up_additional_details_modal/hooks/use_back';
import useNavigateToLogoStep from './hooks/use_navigate_to_logo_step';
import useSubmitStep from 'components/modals/v2/auth/sign_up_additional_details_modal/hooks/use_submit_step';

// https://docs.google.com/spreadsheets/d/17U_U1XJZwtzkQOSv7-xdWPsI2cXf6gruYC6hpzjC9xA/edit#gid=0
const SignUpAdditionalDetails = props => {
  const [step, setStep] = useState(0);
  const [serverError, setError] = useState('');
  const [igError, setIgError] = useState('');
  const modalContext = useContext(ModalContext);
  const { signUpSteps, currentStep, isLastStep } = useSteps(step);
  const { component: Component } = currentStep;
  const index = signUpSteps.indexOf(currentStep);
  const progress = parseInt(((index + 1) / signUpSteps.length) * 100);
  const destroyOngoingStepsData = useDestroyOngoingStepsData();
  const onBack = useBack({ setStep, step, index });
  const navigateToLogoStep = useNavigateToLogoStep(modalContext);

  const onSubmitStep = useSubmitStep({
    index,
    setStep,
    signUpSteps,
    isLastStep,
    navigateToLogoStep,
    setError
  });

  useLayoutEffect(() => {
    destroyOngoingStepsData();
  }, []);

  const componentProps = {
    index,
    igError,
    progress,
    onSubmit: onSubmitStep,
    setIgError,
    setError,
    name: currentStep.name,
    onClose: modalContext.onCloseModal,
    serverError,
    onBack,
    ...props
  };

  return <Component {...componentProps} />;
};

export default SignUpAdditionalDetails;
