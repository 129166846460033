import React, { useEffect, useLayoutEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';

import {
  Wrapper,
  WrapperWithProgressIndent
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Indent from 'components/reusable/indent';
import { StyledForm, StyledField } from './styled';
import Progress from './components/progress';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import ButtonsToolbar from './components/buttons_toolbar';
import CloseButton from './components/close_button';
import Heading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/heading';
import LocationHelpers from 'helpers/location_helpers';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';
import AuthHelper from 'helpers/auth_helper';

const validate = data => {
  const errors = {};
  const value = data[signUpStepsData.location.field];
  const missingLocation = !value;
  const emptyLocation = missingLocation || !Object.values(value).length;
  if (emptyLocation) {
    errors[signUpStepsData.location.field] = 'Please select your city';
  }
  return errors;
};

const SignUpLocationStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  serverError,
  onBack,
  ...props
}) => {
  const isSubmitDisabled = props.invalid || props.submitting;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const user = AuthHelper.currentUser();
    if (user && user.lat && user.lng) {
      const value = {
        address_line: '',
        city: user.city,
        country: user.country,
        input_address: LocationHelpers.getLocationLabel(user),
        lat: user.lat,
        lng: user.lng,
        placeID: user.gmaps_place_id,
        state: user.state
      };
      dispatch(
        change(
          signUpStepsData.location.form,
          signUpStepsData.location.field,
          value
        )
      );
    }
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Progress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <CloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <Heading label="Where are you from?" index={index} required />
        <Indent top={24} />

        <Field
          validate={ffHelpers.required}
          name={signUpStepsData.location.field}
          type="location"
          absolutePredictions
          component={StyledField}
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <ButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  initialValues: {
    [signUpStepsData.location.field]: LocationHelpers.locations.ny
  },
  form: signUpStepsData.location.form,
  destroyOnUnmount: false,
  validate
})(SignUpLocationStep);
