import React, { useLayoutEffect } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch } from 'react-redux';

import {
  Wrapper,
  WrapperWithProgressIndent
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Indent from 'components/reusable/indent';
import { StyledForm, StyledField } from './styled';
import Progress from './components/progress';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import ButtonsToolbar from './components/buttons_toolbar';
import CloseButton from './components/close_button';
import Heading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/heading';
import { validatePhoneNumber } from 'helpers/validation_helper';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';
import AuthHelper from 'helpers/auth_helper';

const validate = data => {
  const errors = {};
  if (
    !data[signUpStepsData.phone.field] ||
    !data[signUpStepsData.phone.field].code ||
    !data[signUpStepsData.phone.field].phone
  ) {
    errors[signUpStepsData.phone.field] = 'Please enter your phone';
  } else if (!validatePhoneNumber(data[signUpStepsData.phone.field].phone)) {
    errors[signUpStepsData.phone.field] = 'Please provide a valid phone number';
  }

  return errors;
};

const SignUpPhoneStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  serverError,
  onBack,
  ...props
}) => {
  const isSubmitDisabled = props.invalid || props.submitting || props.pristine;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const user = AuthHelper.currentUser();
    if (user && user.phone_country_code && user.phone_number) {
      const value = {
        code: `+${user.phone_country_code.replaceAll('+', '')}`,
        phone: user.phone_number
      };
      dispatch(
        change(signUpStepsData.phone.form, signUpStepsData.phone.field, value)
      );
    }
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Progress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <CloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <Heading label="Your phone number" index={index} required />
        <Indent top={24} />

        <Field
          validate={ffHelpers.required}
          target={false}
          name={name}
          type="phone"
          absolutePredictions
          component={StyledField}
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <ButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  form: signUpStepsData.phone.form,
  destroyOnUnmount: false,
  validate,
  initialValues: {
    phone: {
      code: '+1',
      phone: ''
    }
  }
})(SignUpPhoneStep);
