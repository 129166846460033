export const colors = {
  gray: {
    50: '#F7F7F7',
    100: '#EDECEC',
    200: '#D0CECE',
    300: '#B4B1B1',
    400: '#7D7878',
    500: '#5E5A5A',
    600: '#2F2D2D',
    700: '#1F1E1E'
  },
  primary: {
    50: '#FFEDEF',
    100: '#FFDBDE',
    200: '#FFB7BD',
    300: '#FF939C',
    400: '#FF6F7B',
    500: '#FF4B5A',
    600: '#D33C49',
    700: '#A72D37'
  },
  secondary: {
    50: '#E6F8F8',
    100: '#CFF0F0',
    200: '#A3E1E2',
    300: '#76D2D3',
    400: '#4AC3C5',
    500: '#1DB4B6',
    600: '#179092',
    700: '#116C6D'
  },
  light: '#FFFFFF',
  google: '#4F86EC',
  whatsapp: '#25D366'
};
