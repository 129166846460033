import React from 'react';

import HelpContactUsTemplate from 'components/views/help/help_v3/help_contact_us/help_contact_us_template';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

function HelpMessageUs() {
  const items = [
    {
      label: 'Andrea',
      href: 'mailto:support@swipecast.com',
      linkLabel: 'support@swipecast.com',
      icon: svgIcons.questionAnswer(colors.gray[700], { width: 20 })
    },
    {
      label: 'Text us',
      href: 'sms://+1-646-327-6312',
      linkLabel: '+1-646-327-6312',
      icon: svgIcons.whatsApp({ color: colors.whatsapp, width: 20 })
    }
    // {
    //   label: 'WhatsApp',
    //   href: 'https://wa.me/16469609833',
    //   linkLabel: '+1-646-960-9833',
    //   icon: svgIcons.whatsApp({ color: colors.whatsapp, width: 20 })
    // },
  ];

  return <HelpContactUsTemplate items={items} label="Message Us" />;
}

export default HelpMessageUs;
