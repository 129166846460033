import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import jobsV2Sagas from 'store/resources/jobsV2/saga';
import applicationsV2Sagas from 'store/resources/applicationsV2/saga';
import usersV2Sagas from 'store/resources/usersV2/saga';
import applicationsMetaV2Sagas from 'store/resources/applicationsMetaV2/saga';
import sharedVideosV2Sagas from 'store/resources/sharedVideosV2/saga';
import sharedPhotosV2Sagas from 'store/resources/sharedPhotosV2/saga';
import sharedFilesV2Sagas from 'store/resources/sharedFilesV2/saga';
import sharedLinksV2Sagas from 'store/resources/sharedLinksV2/saga';
import jobSaga from './job';
import projectSaga from './project';

/**
 * Will be fired on BOARDS_FETCH_REQUESTED actions
 * @param {object} action
 */
function* loadReviews(action) {
  try {
    const data = yield call(api.loadReviews, action.user_id);
    yield put(actions.fetchReviewsSuccess(data.reviews));
  } catch (e) {
    yield put(actions.fetchReviewsError(e.message));
  }
}

function* loadAlbums(action) {
  try {
    const data = yield call(api.loadAlbums, action.user_id);
    yield put(actions.fetchAlbumsSuccess(data.albums));
  } catch (e) {
    yield put(actions.fetchAlbumsError(e.message));
  }
}

// worker Saga: will be fired on BOARDS_FETCH_REQUESTED actions
function* loadBoards(action) {
  try {
    const boards = yield call(api.loadBoards, action.user_id);
    yield put(actions.fetchBoardsSuccess(boards));
  } catch (e) {
    yield put(actions.fetchBoardsError(e.message));
  }
}

/**
 * JOB_FETCH_REQUESTED
 * @param {object} action
 */
function* loadIndividualJob(action) {
  try {
    const job = yield call(api.loadIndividualJob, action.job_id);
    yield put(actions.fetchIndividualJobSuccess(job));
  } catch (e) {
    yield put(actions.fetchIndividualJobError(e.message));
  }
}

function* loadNotifications() {
  try {
    const data = yield call(api.loadNotifications);
    yield put(actions.fetchNotificationsSuccess(data.notifications));
  } catch (e) {
    yield put(actions.fetchNotificationsError(e.message));
  }
}

function* loadUnreadNotificationsCount() {
  try {
    const data = yield call(api.loadUnreadNotificationsCount);
    yield put(actions.fetchUnreadNotificationsCountSuccess(data.unviewed_notifications_count));
  } catch (e) {
    yield put(actions.fetchUnreadNotificationsCountError(e.response));
  }
}

function* watchLoadIndividualJob() {
  yield takeLatest('JOB_FETCH_REQUESTED', loadIndividualJob);
}

function* watchLoadBoards() {
  yield takeLatest('BOARDS_FETCH_REQUESTED', loadBoards);
}

function* watchLoadReviews() {
  yield takeLatest('REVIEWS_FETCH_REQUESTED', loadReviews);
}

function* watchLoadAlbums() {
  yield takeLatest('ALBUMS_FETCH_REQUESTED', loadAlbums);
}

function* watchLoadNotifications() {
  yield takeEvery('NOTIFICATIONS_FETCH_REQUESTED', loadNotifications);
}

function* watchLoadUnreadNotificationsCount() {
  yield takeEvery('UNREAD_NOTIFICATIONS_COUNT_FETCH_REQUESTED', loadUnreadNotificationsCount);
}

export default function* rootSaga() {
  yield all([
    watchLoadBoards(),
    watchLoadIndividualJob(),
    watchLoadReviews(),
    watchLoadAlbums(),
    watchLoadNotifications(),
    watchLoadUnreadNotificationsCount(),
    ...jobsV2Sagas,
    ...applicationsV2Sagas,
    ...applicationsMetaV2Sagas,
    ...usersV2Sagas,
    ...sharedPhotosV2Sagas,
    ...sharedLinksV2Sagas,
    ...sharedFilesV2Sagas,
    ...sharedVideosV2Sagas,
    jobSaga(),
    projectSaga()
  ]);
}
