export default {
  0: state => ({
    ...state,
    reviews: {
      data: [...state.reviews],
      loading: false
    }
  }),

  1: state => ({
    ...state,
    notifications: {
      data: [...state.notifications],
      loading: false
    },
    unreadNotificationsCount: {
      data: [...state.unreadNotificationsCount],
      loading: false
    }
  }),

  2: state => {
    const newForm = { ...state.form };
    delete newForm['bank_account_form'];
    return { ...state, form: newForm };
  }
};
