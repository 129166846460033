import RouterHelper from 'helpers/router_helper.js';
import PaymentHelpers from 'helpers/payment_helpers.js';
import Globals from 'helpers/globals.js';
import { loadFeedEntries, loadRecentActivity } from 'api/index.js';
import ChatHelpers from 'helpers/chat_helpers';
import AuthHelper from 'helpers/auth_helper';
import ProfileHelpers from 'helpers/profile_helpers';

const $ = window.jQuery;

const UserHelpers = {
  registrationSourceEnum: {
    desktop: 'desktop',
    mr: 'mr'
  },

  shouldAskForWebsite(user) {
    if (!user) return false;

    const askMainProfiles = [Globals.profileTypes.photo];
    const askOtherProfiles = [
      Globals.profileTypes.designer,
      Globals.profileTypes.casting,
      Globals.profileTypes.eComManager,
      Globals.profileTypes.creative,
      Globals.profileTypes.producer,
      Globals.profileTypes.studioManager,
      Globals.profileTypes.studio,
      Globals.profileTypes.other
    ];

    return user.profiles.some(p => askMainProfiles.includes(p.profile_type) || askOtherProfiles.includes(p.data?.type));
  },

  shouldAskForCompany(user) {
    if (!user) return false;

    const askOtherProfiles = [
      Globals.profileTypes.designer,
      Globals.profileTypes.casting,
      Globals.profileTypes.eComManager,
      Globals.profileTypes.creative,
      Globals.profileTypes.producer,
      Globals.profileTypes.studioManager,
      Globals.profileTypes.studio,
      Globals.profileTypes.other
    ];

    return user.profiles.some(p => askOtherProfiles.includes(p.data?.type));
  },

  shouldAskForIG: user => true,
  igMissing: user => UserHelpers.shouldAskForIG(user) && !user.instagram,
  websiteMissing: user => UserHelpers.shouldAskForWebsite(user) && !user.website,
  companyMissing: user => UserHelpers.shouldAskForCompany(user) && !user.organization,
  // https://docs.google.com/spreadsheets/d/1GI9ft-BgtTGO0NkuHQQ_rMAs4_0wUUBQYHzGMQNXMmg/edit#gid=0
  signupUncompleted: user => {
    // undefined for old users
    return (
      user.signup_uncompleted ??
      (UserHelpers.igMissing(user) && UserHelpers.companyMissing(user) && UserHelpers.websiteMissing(user))
    );
  },

  bookable(user) {
    if (!user || user.waitlisted) return false;
    if (user.swipecast_user_id === ChatHelpers.assistantSwipecastUserId) return false;

    return ProfileHelpers.hasTalentProfile(user) && user.profiles[0].bookable;
  },

  getProfileString(user) {
    if (!user.profiles) return '';
    let profiles = user.profiles.filter(p => !['Make-up Artist', 'Hair Stylist'].includes(p.profile_type));
    let profileLabels = profiles.map(p => p.profile_type);
    let hairMakeUpProfiles = user.profiles.filter(p => ['Make-up Artist', 'Hair Stylist'].includes(p.profile_type));
    let hairMakeUpLabel = '';

    if (hairMakeUpProfiles.length === 1) hairMakeUpLabel = hairMakeUpProfiles[0].profile_type;
    if (hairMakeUpProfiles.length === 2) hairMakeUpLabel = 'Hair & Make-Up Artist';

    return [hairMakeUpLabel, ...profileLabels].filter(v => !!v).join(' & ');

    // var profile_string;
    // var profile_type0 = get(user, 'profiles[0].profile_type');
    // if (user.profiles[1]) {
    //   var profile_type1 = get(user, 'profiles[1].profile_type');
    //   if (
    //     (profile_type0 === 'Make-up Artist' ||
    //       profile_type0 === 'Hair Stylist') &&
    //     (profile_type1 === 'Make-up Artist' || profile_type1 === 'Hair Stylist')
    //   ) {
    //     profile_string = 'Hair & Make-Up Artist';
    //   } else {
    //     profile_string = profile_type0;
    //   }
    // } else {
    //   profile_string = profile_type0;
    // }
    // return profile_string || '';
  },

  isModel(user) {
    if (!user.profiles) return false;
    return user.profiles.some(p => p.profile_type === 'Model');
  },

  /**
   * @description a person who sends a Link-in-Bio referral link
   * @param swipecast_user_id
   * @returns {boolean}
   */
  isLibReferrer(swipecast_user_id) {
    if (!swipecast_user_id) return false;
    return AuthHelper.currentUser().lib_referrer_swipecast_id === swipecast_user_id;
  },

  /**
   * @description a person who sends a Special Booking Offer
   * @param swipecast_user_id
   * @returns {boolean}
   */
  isOfferer(swipecast_user_id) {
    if (!swipecast_user_id) return false;
    return AuthHelper.currentUser().offerer_swipecast_id === swipecast_user_id;
  },

  canInviteToJob(user) {
    if (!user || !AuthHelper.currentUser()) return false;
    if (AuthHelper.currentUser().swipecast_user_id === user.swipecast_user_id) return false;

    return AuthHelper.canInviteToJob && ProfileHelpers.hasTalentProfile(user);
  },

  canBook(user) {
    if (!user) return false;
    if (UserHelpers.isLibReferrer(user.swipecast_user_id)) return true; // just in case

    return UserHelpers.bookable(user) && AuthHelper.canBookTalent;
  },

  getUserTitle(user) {
    let label = '';
    if (this.isModel(user)) {
      // we SHOULD NOT show organization for models
      return this.getProfileString(user);
    } else {
      // maybe we should hide organization if user has multiple profiles?
      var profile_string = this.getProfileString(user);

      if (user.job_title && user.organization) {
        label = user.job_title + ' at ' + user.organization;
      } else {
        if (user.job_title) {
          label = user.job_title;
        } else {
          if (user.organization) {
            label = profile_string + ' at ' + user.organization;
          } else {
            label = profile_string;
          }
        }
      }
    }
    return label;
  },

  loadUser(swipecast_user_id, callback, error = () => {}) {
    if (!swipecast_user_id) return;
    $.ajax({
      method: 'GET',
      url: '/user/' + swipecast_user_id,
      dataType: 'JSON',
      data: {
        include_stripe_customer: true,
        include_stripe_accounts: true
      },
      success: requestData => {
        callback(requestData.user);
      },
      error: xhr => {
        error(xhr);
      }
    });
  },

  loadUserAsync(swipecast_user_id) {
    return new Promise((resolve, reject) => this.loadUser(swipecast_user_id, resolve, reject));
  },

  editUser(swipecast_user_id, fields, callback, error_callback) {
    $.ajax({
      method: 'PUT',
      url: '/user/' + swipecast_user_id,
      data: {
        user: fields
      },
      dataType: 'JSON',
      success: requestData => {
        if (callback) callback(requestData);
      },
      error: error => {
        if (error_callback) {
          error_callback(error);
        } else {
          console.log(error);
        }
      }
    });
  },

  linkInstagram(code, callback_success, callback_error) {
    $.ajax({
      method: 'POST',
      url: '/user/link_instagram',
      dataType: 'JSON',
      data: {
        code: code,
        redirect_uri: RouterHelper.root + 'instagram'
      },
      success: requestData => {
        callback_success(requestData.result);
      },
      error: error => {
        console.log(error);
        callback_error(error);
      }
    });
  },

  unLinkInstagram(callback_success, callback_error) {
    $.ajax({
      method: 'POST',
      url: '/user/unlink_instagram',
      dataType: 'JSON',
      data: {},
      success: requestData => {
        callback_success(requestData.result);
      },
      error: error => {
        console.log(error);
        callback_error(error);
      }
    });
  },

  editProfile(swipecast_profile_id, fields, callback) {
    $.ajax({
      method: 'PUT',
      url: '/profile/' + swipecast_profile_id,
      data: {
        profile: fields
      },
      dataType: 'JSON',
      success: requestData => {
        callback(requestData);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  loadConnections(swipecast_user_id, callback, limit = null) {
    const params = { status: 'connected' };
    if (limit) {
      params['limit'] = limit;
    }
    $.ajax({
      method: 'GET',
      url: '/user/' + swipecast_user_id + '/connections',
      dataType: 'JSON',
      data: params,
      success: requestData => {
        callback(requestData.connections);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  loadRequests(swipecast_user_id, callback) {
    $.ajax({
      method: 'GET',
      url: '/user/' + swipecast_user_id + '/connections',
      dataType: 'JSON',
      data: {
        status: 'requested',
        origin: 'requestee'
      },
      success: requestData => {
        callback(requestData.connections);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  addCard(token, callback) {
    PaymentHelpers.loadStripeCustomers(customers => {
      if (customers.length === 0) {
        $.ajax({
          method: 'POST',
          url: '/stripe_customer',
          dataType: 'JSON',
          data: {
            stripe_token: token
          },
          success: requestData => {
            callback(requestData.stripe_customer);
          },
          error: error => {
            console.log(error);
            callback(error);
          }
        });
      } else {
        let stripe_customer_id = customers[0].stripe_customer_id;

        $.ajax({
          method: 'POST',
          url: '/stripe_customer/' + stripe_customer_id + '/add_source',
          dataType: 'JSON',
          data: {
            stripe_token: token
          },
          success: requestData => {
            callback(requestData.stripe_customer);
          },
          error: error => {
            console.log(error);
            callback(error);
          }
        });
      }
    });
  },

  /**
  @deprecated: Use `helpers/api` instead
  */
  loadUsers(category, offset, limit, additional_filters, callback, count) {
    let filters = Object.assign({}, Globals.categories[category].filters, additional_filters);

    $.ajax({
      method: 'POST',
      url: '/query',
      dataType: 'JSON',
      contentType: 'application/json',
      data: JSON.stringify({
        query: {
          filters: filters,
          offset: offset,
          limit: limit,
          type: 'user_profile',
          order_by: [{ manual_ordering: 'desc' }],
          count: count
        }
      }),
      success: data => {
        callback(data.result);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  /*
  makes a call to our backend to let us know that the current user (whether logged in or not)
  viewed the user swipecast_user_id
  */
  viewedUserProfile(swipecast_user_id) {
    $.ajax({
      url: `/user/${swipecast_user_id}/views`,
      method: 'POST',
      data: {},
      dataType: 'JSON',
      error: (jqXHR, textStatus, errorThrown) => {},
      success: (data, textStatus, jqXHR) => {}
    });
  },

  loadDashboard() {
    return Promise.all([loadFeedEntries(), loadRecentActivity()]);
  }
};

export default UserHelpers;
