import React, { useContext, useState, useEffect, useCallback } from 'react';
import { reduxForm } from 'redux-form';

import SignUpByEmailForm from './components/sign_up_by_email_form';
import { ModalContext, RootContext } from 'contexts/contexts';
import { validate } from './helpers/validation_helper';
import useSubmit from 'components/modals/v2/auth/sign_up_by_email_modal/hooks/use_submit';
import { modalEnum } from 'components/modals/v2/modal';

const SignUpByEmail = ({ handleSubmit, ...props }) => {
  const modalContext = useContext(ModalContext);
  const rootContext = useContext(RootContext);
  const [error, setError] = useState('');
  const onSubmit = useSubmit({ rootContext, setError, modalContext });
  const isSubmitDisabled = props.invalid || props.submitting || props.pristine;
  const onLogin = useCallback(() => modalContext.onOpenModal(modalEnum.signIn));

  // useEffect(() => {
  //   props.reset();
  // }, []);

  return (
    <SignUpByEmailForm
      isSubmitDisabled={isSubmitDisabled}
      onClose={modalContext.onCloseModal}
      onSubmit={handleSubmit(onSubmit)}
      onLogin={onLogin}
      error={error}
    />
  );
};

export default reduxForm({
  validate,
  form: 'signUpByEmail'
  // initialValues: {
  //   password: Math.random().toString(36),
  // },
  // destroyOnUnmount: false
})(SignUpByEmail);
