import React from 'react';

import HelpContactUsTemplate from 'components/views/help/help_v3/help_contact_us/help_contact_us_template';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import SvgPhoneOutline from 'components/reusable/svg/svg_phone_outline';

function HelpCallUs() {
  const items = [
    {
      label: 'Phone number',
      linkLabel: '+1-415-800-5959',
      href: 'tel:+1-415-800-5959',
      icon: <SvgPhoneOutline size={20} />
    },
    {
      label: 'WhatsApp',
      linkLabel: '+1-646-327-6312',
      href: 'https://wa.me/16463276312',
      icon: svgIcons.whatsApp({ color: colors.whatsapp, width: 20 })
    }
  ];

  return <HelpContactUsTemplate items={items} label="Call Us" />;
}

export default HelpCallUs;
