import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import Button from 'components/reusable/button';

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

const CloseButton = ({ onClick }) => {
  return (
    <StyledButton type="semantic-wrapper" onClick={onClick}>
      {svgIcons.close(colors.gray[700])}
    </StyledButton>
  );
};

export default CloseButton;
