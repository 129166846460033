import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import CalendarDay, { cellWidth } from './components/day';
import DateHelpers from 'helpers/date_helpers';
import Text from 'components/reusable/text';
import {
  BeforeFirstMonthDayOffset,
  WeekDay,
  Wrapper,
  NextMonthArrow,
  PrevMonthArrow,
  CalendarGrid
} from './styled';
import Indent from 'components/reusable/indent';
import useValuesManipulator, {
  dateToNumeric
} from './hooks/use_values_manipulator';
import useLegacyData from './hooks/use_legacy_data';
import useDisabled from './hooks/use_disabled';
import useNodeDimensions from 'hooks/use_node_dimensions';

export const secondCalendarCondition = window.innerWidth > 1200;
const gridGap = 4;

const MonthCalendarInput = ({
  className,
  fullWidth,
  value,
  withoutToday,
  onChange,
  monthAhead,
  disableControls
}) => {
  const initialDate = new Date();
  // const date = new Date("2022-10-30T20:00:00.000-04:00");;
  if (monthAhead) {
    initialDate.setDate(1);
    initialDate.setMonth(initialDate.getMonth() + 1);
  }

  const [{ month, year }, changeMonth] = useState({
    month: initialDate.getMonth(),
    year: initialDate.getFullYear()
  });
  const monthYearLabel = `${DateHelpers.fullMonthNames[month]} ${year}`;
  const firstDayOfMonthWeekday = new Date(year, month, 1).getDay();
  const { targetRef, dimensions } = useNodeDimensions();

  const daysInCalendar = useMemo(() => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }, [year, month]);

  const { isNextMonthAvailable, isPrevMonthAvailable } = useLegacyData({
    initialDate,
    month,
    year
  });

  const { incrementMonth, decrementMonth, onClick } = useValuesManipulator({
    month,
    year,
    changeMonth,
    onChange,
    value
  });

  const { getUseDisabled } = useDisabled({ month, year, withoutToday });

  const cellSize = useMemo(() => {
    if (!fullWidth) return cellWidth;
    if (fullWidth) {
      const columns = 7;
      const columnsSize = dimensions.width - (columns - 1) * gridGap;
      const columnSize = columnsSize / columns;
      return columnSize ? `${Math.floor(columnSize)}px` : cellWidth; // if error in calculations, just in case
    }
  }, [fullWidth, dimensions.width, gridGap]);

  return (
    <Wrapper $fullWidth={fullWidth} ref={targetRef} className={className}>
      <Indent bottom={20} />
      <StyledText size={18} weight={500}>
        {monthYearLabel}
      </StyledText>

      {!disableControls && (
        <>
          {isPrevMonthAvailable && (
            <PrevMonthArrow
              onClick={decrementMonth}
              className="fa fa-angle-left"
            />
          )}
          {isNextMonthAvailable && (
            <NextMonthArrow
              onClick={incrementMonth}
              className="fa fa-angle-right"
            />
          )}
        </>
      )}

      <CalendarGrid $gap={`${gridGap}px`} className="calendar-grid">
        {DateHelpers.shortDaysOfWeek.map(day => (
          <WeekDay cellWidth={cellWidth} key={day}>
            {day.toLowerCase()}
          </WeekDay>
        ))}

        <BeforeFirstMonthDayOffset
          firstDayOfMonthWeekday={firstDayOfMonthWeekday}
        />

        {daysInCalendar.map(day => {
          const myDate = new Date(year, month, day);
          const selected = value.includes(dateToNumeric(myDate));
          const isDisabled = getUseDisabled(day);
          const handleClick = () => onClick(day);

          return (
            <CalendarDay
              key={day}
              cellSize={cellSize}
              onClick={handleClick}
              isSelected={selected}
              day={day}
              month={month}
              year={year}
              isDisabled={isDisabled}
            />
          );
        })}
      </CalendarGrid>
    </Wrapper>
  );
};

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

export default MonthCalendarInput;
