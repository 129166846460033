import React from 'react';

import NotificationHelpers from 'helpers/notification_helpers';
import ConnectHelpers from 'helpers/connect_helpers';
import NotificationSnapshot from 'components/views/logged_in/notifications/notification_components/notification_snapshot';

class NotificationSnapshotContainer extends React.Component {
  state = null;

  markAsRead = () => {
    NotificationHelpers.readNotification(
      this.props.notification.id,
      this.props.fetchNotifications
    );
  };

  handleClick = (element_clicked = 'body_of_notification') => {
    let notification_data = this.props.notification.data;
    let notification_type = this.props.notification.notification_type;
    this.markAsRead(this.props.notification.id);

    if (notification_type === 'proposeConnection') {
      if (element_clicked === 'body_of_notification') {
        this.props.rootProps.toggleConnectRequestRecipient({
          swipecast_user_id: notification_data.swipecast_user_id
        });
      } else if (element_clicked === 'delete') {
        // this.props.activateLoading();
        ConnectHelpers.declineConnection(
          notification_data.swipecast_user_id,
          notification_data.connection_id,
          this.props.fetchNotifications
        );
      } else if (element_clicked === 'accept') {
        ConnectHelpers.acceptConnection(
          notification_data.swipecast_user_id,
          notification_data.connection_id,
          this.props.fetchNotifications
        );
      }
    }
    if (notification_type === 'waitingForTalentToAccept') {
      window.location.href = '/my_jobs';
    }
    if (
      [
        'inviteToJob',
        'clientSelectTalent',
        'expiredJob',
        'completedJob',
        'cardChargeFailed',
        'talentApplyToJob',
        'jobApproved',
        'newJobNearby',
        'clientCancelJob',
        'clientDeselectTalent',
        'talentAccept',
        'talentDecline',
        'talentWithdraw',
        'thirdPartyPaymentEntered',
        'confirmedJob'
      ].indexOf(notification_type) !== -1
    ) {
      window.location.href = '/jobs/' + notification_data.swipecast_job_id;
    }
    if (notification_type === 'projectApproved') {
      window.location.href =
        '/project/' + notification_data.swipecast_project_id;
    }
    if (notification_type === 'acceptConnection') {
      window.location.href = '/profile/' + notification_data.swipecast_user_id;
    }
    if (notification_type === 'inviteToCasting') {
      window.location.href = '/jobs/' + notification_data.swipecast_job_id;
    }
    if (notification_type === 'confirmedCasting') {
      window.location.href = '/jobs/' + notification_data.swipecast_job_id;
    }
  };

  render() {
    return (
      <NotificationSnapshot
        notification={this.props.notification}
        clickOnElement={clicked_element => {
          this.handleClick(clicked_element);
        }}
      />
    );
  }
}

export default NotificationSnapshotContainer;
