export const validateEmail = email => {
  if (!email || email.trim() === '') return false;
  // eslint-disable-next-line
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePassword = password => {
  if (!password || password.trim() === '') return false;
  let re = /(?=.*\d)([\W]|[\w]){8,}/;
  return re.test(password);
};

export const validatePhoneNumber = phone => {
  if (!phone || phone.toString().trim() === '') return false;
  return phone.trim().length >= 5;
};
