import { css } from 'styled-components';

export const hideScrollingIndicator = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  > ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

export const heightSameAsWidth = css`
  width: 100%;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

// do not combine identical values.
// those are technically different components which can change separately in future
export const styleDimensions = {
  mobileBarHeight: '49px',
  mobileHeaderHeight: '48px',
  desktopHeaderHeight: '80px',
  desktopBtnToolbarHeight: '80px',
  chatInputHeight: '80px',
  conversationHeaderHeightMR: '60px',
  desktopOfferProfileFooterHeight: '80px',
  desktopLiBFooterHeight: '80px',
  desktopAlbumPreviewHeaderHeight: '80px',
  mobileAlbumPreviewHeaderHeight: '48px'
};

export const getDimention = value => (typeof value === 'number' ? `${value}px` : value);

export const isMobileWidth = window.innerWidth <= 768;
export const dimensions = {
  width: window.innerWidth,
  height: window.innerHeight
};
