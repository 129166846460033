import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import queryString from 'query-string';

import AuthHelper from 'helpers/auth_helper';
import GeneralRoute from 'components/reusable/routes/general_route';
import { routes } from 'helpers/router_helper';
import PrivateRoute from 'components/reusable/routes/private_route';
import ApplicationHelper from 'helpers/application_helper';
import NotificationView from 'components/views/logged_in/notifications/notification_view';
import OuterRoute from 'components/reusable/routes/outer_route';
import { DemiScott } from 'components/views/static_pages/demi_scott';
import { AbContext } from 'contexts/contexts';
import { guestVariations } from 'hocs/with_ab_provider';
// eslint-disable
import {
  JobsFiltersView,
  NewHelpSectionView,
  HamburgerMenu,
  BankAccountView,
  EmailReadyIndividualBoardView,
  CreateJobView,
  MyProfileFinishSocialSignUp,
  ResetPassword,
  PrivacyPolicy,
  DeadEnd,
  Contact,
  LandingView,
  SignUpPage,
  LoginPage,
  TermsOfService,
  HelpView,
  WaitlistView,
  HelpFAQView,
  Socials,
  SettingsView,
  ContactsView,
  RequestsView,
  DiscoverTalentCategoriesView,
  SearchInputView,
  ChatView,
  SearchView,
  NetworkFilters,
  IndividualBoardView,
  PaymentSettingsView,
  MyProfileStatsView,
  MyProfileImagesAlbumView,
  MyProfileImagesView,
  MyProfileChangePassword,
  NewBookingOfferView,
  AllJobsViewV2,
  MyJobsViewV2,
  DiscoverView,
  BoardsView,
  MyProfileBasicInfoView,
  MyProfileUserView,
  UserProfileView,
  ThirdPartyPayView,
  SearchResultsWrapper,
  CreateJobSuccessView,
  EditProfile,
  SwipecastProView,
  GuestLanding,
  GuestSignUp,
  AddCardView,
  IndividualProjectViewV3,
  EditProjectTitleView,
  EditProjectPictureView,
  CreateJobRoleViewV2,
  ChatViewOLD,
  IndividualJobViewV2,
  EditJobRateView,
  EditJobDatesView,
  EditJobTimeView,
  EditJobLocationView,
  EditJobNotesView,
  EditProjectView,
  EditJobView,
  ProfileShareV2,
  NewBookingOfferSummaryView,
  DownloadApp,
  BookingOfferView,
  LinkInBioShare,
  OnboardingView,
  EditBookingOfferView
} from './loadable_routes';
import HelpCallUs from 'components/views/help/help_v3/help_contact_us/help_call_us';
import HelpMessageUs from 'components/views/help/help_v3/help_contact_us/help_message_us';
import Settings from 'helpers/settings';
// eslint-enable

function Router({ rootProps, utm, state, hideChatWindow, toggleMessenger }) {
  const abContext = useContext(AbContext);
  return (
    <>
      <Switch>
        <Redirect from="/finish_profile" to="/" />
        <GeneralRoute
          path="/logout"
          render={() => {
            AuthHelper.logout();
            window.location.replace(routes.login.redirectUrl());
          }}
        />
        <GeneralRoute path="/mobile" component={DownloadApp} />
        {AuthHelper.logged_in && <GeneralRoute exact path={routes.discover.base.path} component={DiscoverView} />}
        {AuthHelper.logged_in && (
          <GeneralRoute exact path={routes.discover.categories.path} component={DiscoverTalentCategoriesView} />
        )}
        {AuthHelper.logged_in && !AuthHelper.logged_in_waitlisted && (
          <GeneralRoute exact path={routes.network.input.path} component={SearchInputView} />
        )}
        <PrivateRoute
          path={routes.chat.path}
          component={ChatView}
          // render={routeProps => <ChatViewOLD rootProps={rootProps} {...routeProps} />}
        />
        <PrivateRoute path="/notifications" component={NotificationView} />
        <GeneralRoute path="/third_party_pay/:secret_alias_id" component={ThirdPartyPayView} />
        <GeneralRoute
          exact
          path={routes.resetPassword.path}
          render={() => {
            const code = queryString.parse(window.location.search).code;
            return <ResetPassword code={code} />;
          }}
        />
        <GeneralRoute exact path={routes.about.terms.path} component={TermsOfService} />
        <GeneralRoute exact path={routes.about.policy.path} component={PrivacyPolicy} />
        <GeneralRoute
          exact
          path="/position/:slug"
          render={routeProps => <SearchResultsWrapper rootProps={rootProps} {...routeProps} />}
        />
        <OuterRoute
          exact
          path="/sign_up"
          render={routeProps => {
            return <SignUpPage {...routeProps} utm={utm} />;
          }}
        />
        <OuterRoute exact path={routes.login.path} component={LoginPage} />
        <GeneralRoute exact path="/contact" component={Contact} />
        <GeneralRoute exact path="/demi-scott" component={DemiScott} />
        <GeneralRoute
          exact
          path="/boards/:swipecast_board_id"
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);
            const props = {
              rootProps,
              swipecast_board_id: routeProps.match.params.swipecast_board_id
            };
            if (query_string.email_friendly) {
              return <EmailReadyIndividualBoardView {...routeProps} {...props} />;
            }
            return <IndividualBoardView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.projectShow.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            const props = {
              swipecast_project_id: routeProps.match.params.swipecast_project_id,
              project_share_id: query_string.share_id || query_string.pid || null,
              section: routeProps.match.params.section || null,
              rootProps
            };
            // TODO do we really need AuthHelper.settings.newJobFlow ???
            return <IndividualProjectViewV3 {...routeProps} {...props} />;
            // return <IndividualProjectViewNew {...routeProps} {...props} />;
            // if (AuthHelper.settings && AuthHelper.settings.newJobFlow) {
            //   return (
            //     <IndividualProjectViewNew {...routeProps} {...props} />
            //   );
            // }
            // return <IndividualProjectView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.projectEdit.main.path}
          render={routeProps => {
            const props = {
              swipecast_project_id: routeProps.match.params.swipecast_project_id,
              rootProps
            };

            return <EditProjectView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.projectEdit.title.path}
          render={routeProps => {
            const props = {
              swipecast_project_id: routeProps.match.params.swipecast_project_id,
              rootProps
            };

            return <EditProjectTitleView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.projectEdit.picture.path}
          render={routeProps => {
            const props = {
              swipecast_project_id: routeProps.match.params.swipecast_project_id,
              rootProps
            };

            return <EditProjectPictureView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.createProjectRole.path}
          render={routeProps => {
            const props = {
              swipecast_project_id: routeProps.match.params.swipecast_project_id,
              rootProps
            };

            return <CreateJobRoleViewV2 {...routeProps} {...props} />;
          }}
        />
        {ApplicationHelper.isMobile ? (
          <PrivateRoute exact path={routes.jobs.filters.path} component={JobsFiltersView} />
        ) : null}
        <GeneralRoute
          exact
          path={routes.jobs.show.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              project_share_id: query_string.pid || null,
              job_share_id: query_string.share_id || query_string.jid || null,
              rootProps
            };

            return <IndividualJobViewV2 {...routeProps} {...props} />;
            // return <IndividualProjectViewNew {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.main.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.rate.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobRateView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.dates.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobDatesView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.time.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobTimeView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.location.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobLocationView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute
          exact
          path={routes.jobs.edit.notes.path}
          render={routeProps => {
            const query_string = queryString.parse(window.location.search);

            if (!AuthHelper.logged_in && !(query_string.jid || query_string.pid || query_string.share_id)) {
              return (
                <Redirect
                  to={{
                    pathname: routes.login.redirectUrl(),
                    state: { from: routeProps.location }
                  }}
                />
              );
            }

            const props = {
              swipecast_job_id: routeProps.match.params.swipecast_job_id,
              rootProps
            };

            return <EditJobNotesView {...routeProps} {...props} />;
          }}
        />
        <GeneralRoute exact path={routes.deadEnd.path} component={DeadEnd} />
        {!AuthHelper.logged_in && (
          <GeneralRoute
            exact
            path={routes.logged_out.landing.path}
            render={routeProps => {
              return <LandingView rootProps={rootProps} {...routeProps} />;
            }}
          />
        )}
        {!AuthHelper.logged_in && (
          <GeneralRoute exact path={routes.logged_out.swipecastPro.path} component={SwipecastProView} />
        )}
        {ApplicationHelper.isMobile ? (
          <PrivateRoute exact path={routes.network.filters.path} component={NetworkFilters} />
        ) : null}
        <Redirect exact from={routes.network.base.path} to={routes.network.talents.redirectUrl('all')} />
        <PrivateRoute exact path={routes.network.talents.path} render={() => <SearchView rootProps={rootProps} />} />
        {/* TODO remove */}
        <PrivateRoute exact path={routes.finishProfile.path} component={MyProfileFinishSocialSignUp} />
        <PrivateRoute exact path="/basic_info" component={MyProfileBasicInfoView} />
        {ApplicationHelper.isMobile ? (
          <PrivateRoute exact path={routes.profileQrShare.path} component={ProfileShareV2} />
        ) : null}
        <PrivateRoute exact path="/stats" component={MyProfileStatsView} />
        <PrivateRoute exact path="/requests" component={RequestsView} />
        <PrivateRoute exact path="/contacts" component={ContactsView} />
        <PrivateRoute exact path="/settings" component={SettingsView} />
        <PrivateRoute exact path={routes.createProjectSuccess.path} component={CreateJobSuccessView} />
        <GeneralRoute exact path={routes.createProject.path} component={CreateJobView} />
        {abContext.guestVariation === guestVariations.a ? (
          <GeneralRoute exact path={routes.guest.path} component={GuestLanding} />
        ) : null}
        <GeneralRoute exact path={routes.guestSignUp.path} component={GuestSignUp} />
        {/* <PrivateRoute
          exact
          path="/payment_settings"
          render={routeProps => (
            <PaymentSettingsViewOLD rootProps={rootProps} {...routeProps} />
          )}
        /> */}
        <PrivateRoute exact path={routes.bankAccount.path} component={BankAccountView} />
        <PrivateRoute exact path="/card_info" component={AddCardView} />
        <PrivateRoute exact path={routes.paymentSettings.path} component={PaymentSettingsView} />
        <PrivateRoute
          exact
          path={routes.boards.path}
          render={routeProps => <BoardsView rootProps={rootProps} {...routeProps} />}
        />
        {ApplicationHelper.isMobile && (
          <GeneralRoute
            exact
            path={routes.help.path}
            render={routeProps => <HelpView rootProps={rootProps} {...routeProps} />}
          />
        )}
        {ApplicationHelper.isMobile && <GeneralRoute exact path={routes.waitlist.path} component={WaitlistView} />}
        <Redirect from="/faq" to={routes.faq.path} />{' '}
        {/* some external servicies and old IOS versions could use this */}
        <GeneralRoute exact path={routes.faq.path} component={HelpFAQView} />
        {ApplicationHelper.isMobile && <GeneralRoute exact path={routes.helpCallUs.path} component={HelpCallUs} />}
        {ApplicationHelper.isMobile && (
          <GeneralRoute exact path={routes.helpMessageUs.path} component={HelpMessageUs} />
        )}
        <GeneralRoute exact path="/socials" component={Socials} />
        <GeneralRoute exact path="/help/:help_section" component={NewHelpSectionView} />
        <PrivateRoute
          exact
          path="/settings"
          render={routeProps => <SettingsView rootProps={rootProps} {...routeProps} />}
        />
        {ApplicationHelper.isMobile && <PrivateRoute exact path={routes.menu.path} component={HamburgerMenu} />}
        <PrivateRoute exact path={routes.profileShare.path} component={LinkInBioShare} />
        <PrivateRoute exact path={routes.profileOnboarding.path} component={OnboardingView} />
        {ApplicationHelper.isMobile && <PrivateRoute exact path={routes.profileEdit.path} component={EditProfile} />}
        <PrivateRoute exact path={routes.myProfile.path} component={MyProfileUserView} />
        <PrivateRoute exact path={routes.user.me.albums.path} component={MyProfileImagesAlbumView} />
        {ApplicationHelper.isMobile && (
          <PrivateRoute exact path={routes.user.me.albumView.path} component={MyProfileImagesView} />
        )}
        <PrivateRoute exact path="/change_password" component={MyProfileChangePassword} />
        <PrivateRoute exact path={routes.jobs.list.path} component={AllJobsViewV2} />
        <PrivateRoute exact path={routes.jobs.my.path} component={MyJobsViewV2} />
        {/* BOOKING OFFER START */}
        {Settings.bookingOffersEnabled && (
          <PrivateRoute exact path={routes.newBookingOffer.path} component={NewBookingOfferView} />
        )}
        {Settings.bookingOffersEnabled && (
          <PrivateRoute exact path={routes.editBookingOffer.path} component={EditBookingOfferView} />
        )}
        {Settings.bookingOffersEnabled && (
          <PrivateRoute exact path={routes.newBookingOfferSummary.path} component={NewBookingOfferSummaryView} />
        )}
        {Settings.bookingOffersEnabled && (
          <GeneralRoute exact path={routes.bookingOfferShow.path} component={BookingOfferView} />
        )}
        {/* BOOKING OFFER END */}
        {/* TODO add profile nesting to route (/profile/...
        ) so we can redirect to the deadend if route is not present*/}
        <GeneralRoute exact path={routes.userProfile.path} component={UserProfileView} />
        <Redirect from="*" to={routes.deadEnd.path} />
      </Switch>

      {/*<GeneralRoute*/}
      {/*  render={routeProps => {*/}
      {/*    if (*/}
      {/*      AuthHelper.logged_in &&*/}
      {/*      !AuthHelper.logged_in_waitlisted &&*/}
      {/*      !routeProps.location.pathname.includes('/chat') &&*/}
      {/*      !ApplicationHelper.isMobile &&*/}
      {/*      state.mode !== 'embed'*/}
      {/*    ) {*/}
      {/*      return (*/}
      {/*        <ChatMiniConversationLayer*/}
      {/*          swipecast_user_ids={state.active_mini_chat_window_user_ids}*/}
      {/*          hideChatWindow={hideChatWindow}*/}
      {/*          toggleMessenger={toggleMessenger}*/}
      {/*          messenger_active={state.messenger_active}*/}
      {/*          rootProps={rootProps}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    }*/}
      {/*    return <div />;*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
}

export default Router;
