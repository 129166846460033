import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch } from 'react-redux';

import { WrapperWithProgressIndent } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Indent from 'components/reusable/indent';
import { StyledForm, StyledField } from './styled';
import Progress from './components/progress';
import ButtonsToolbar from './components/buttons_toolbar';
import CloseButton from './components/close_button';
import Heading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/components/heading';
import Text from 'components/reusable/text';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';
import AuthHelper from 'helpers/auth_helper';

const TipWrapper = styled.div`
  width: 231px;
`;

const tip = (
  <TipWrapper>
    <Text size={12}>
      If you have a company website, please share the link. This will speed up
      the process of letting you off the waitlist.
    </Text>
  </TipWrapper>
);

const SignUpCompanyStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  serverError,
  onBack,
  ...props
}) => {
  const dispatch = useDispatch();
  const isSubmitDisabled = props.invalid || props.submitting;

  useLayoutEffect(() => {
    const user = AuthHelper.currentUser();
    if (user && user.website) {
      dispatch(
        change(
          signUpStepsData.website.form,
          signUpStepsData.website.field,
          user.website
        )
      );
    }
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Progress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <CloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <Heading label="Your website" index={index} tip={tip} />
        <Indent top={24} />

        <Field
          name={name}
          validate={ffHelpers.required}
          component={StyledField}
          placeholder="http://website.com"
          autoComplete="url"
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <ButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  form: signUpStepsData.website.form,
  destroyOnUnmount: false
})(SignUpCompanyStep);
