import React, { useCallback } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import get from 'lodash.get';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import SvgSaveOutline from 'components/reusable/svg/svg_save_outline';
import SvgTrashOutline from 'components/reusable/svg/svg_trash_outline';
import FileIconPicker from 'components/complex/file_icon_picker';
import FileHelpers from 'helpers/file_helpers';
import AuthHelper from 'helpers/auth_helper';
import SvgLinkOutline from 'components/reusable/svg/svg_link_outline';
import RouterHelper from 'helpers/router_helper';
import SvgCopyOutline from 'components/reusable/svg/svg_copy_outline';
import StringHelpers from 'helpers/string_helpers';
import ChatIconActions from 'components/modals/v2/chat_shared_modal/components/chat_icon_actions';

function ChatSharedRowItem({ handleDeleteSharedMsg, messageType, message, isSkeleton = false }) {
  const size = message?.metadata?.size;
  const fileSize = isSkeleton ? null : size ? FileHelpers.bytesLabel(size) : 'unknown size';
  const isMyMsg = isSkeleton ? null : message.swipecast_user_id === AuthHelper.logged_in_profileID;
  const url = isSkeleton ? null : message.message;
  const isFile = message?.message_type === 'file';
  const isLink = message?.message_type === 'link';
  const fileName = isSkeleton ? null : message.metadata?.file_name || url.split('/').at(-1);
  const linkURL = isSkeleton ? null : get(message, 'message_links[0].url');
  const primaryLabel = isSkeleton ? null : isFile ? fileName : RouterHelper.getHostURL(linkURL);
  const secondaryLabel = isSkeleton ? null : isFile ? fileSize : get(message, 'message_links[0].url');

  const handleDownloadFile = useCallback(() => FileHelpers.triggerDownload(url), [url]);

  const handleCopyLink = useCallback(async () => {
    if (!linkURL) return false;
    const result = await StringHelpers.copyToClipboard(linkURL);
    if (result) alert('Copied to clipboard');
  }, [linkURL]);

  const handleDeleteFile = useCallback(() => {
    handleDeleteSharedMsg(message.id, messageType);
  }, [message?.id, messageType]);

  const actions = [
    {
      skeletonCondition: isFile,
      condition: isFile,
      Icon: SvgSaveOutline,
      onClick: handleDownloadFile,
      label: 'Download'
    },
    {
      skeletonCondition: isFile,
      condition: isMyMsg && isFile,
      Icon: SvgTrashOutline,
      onClick: handleDeleteFile,
      label: 'Delete'
    },
    { skeletonCondition: isLink, condition: isLink, Icon: SvgCopyOutline, onClick: handleCopyLink, label: 'Copy' }
  ].filter(a => a.condition);

  return (
    <RowItem type="semantic-wrapper" className="chat__shared-row-item">
      {isSkeleton ? (
        <Skeleton width={30} height={38} />
      ) : isFile ? (
        <FileIconPicker url={url} size={30} color={colors.primary[500]} />
      ) : (
        <SvgLinkOutline size={30} color={colors.primary[500]} />
      )}

      <Flex dir="column" gap={4} flex={1} ai="flex-start">
        {isSkeleton ? (
          <Skeleton height={19} width={200} />
        ) : (
          <Text size={14} weight={500}>
            {primaryLabel}
          </Text>
        )}

        {isSkeleton ? (
          <Skeleton height={16} width={50} />
        ) : (
          <StyledSize size={12} weight={400} color={colors.gray[300]}>
            {secondaryLabel}
          </StyledSize>
        )}
      </Flex>

      <ChatIconActions actions={actions} isSkeleton={isSkeleton} />
    </RowItem>
  );
}

const StyledSize = styled(Text)`
  min-height: 16px;
`;

const RowItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 0px 0px 8px 0px rgba(31, 30, 30, 0.08);
  border-radius: 12px;
  > :first-child {
    margin-right: 4px;
  }
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export default ChatSharedRowItem;
