import React from 'react';
import styled from 'styled-components';
import { getDimention } from 'helpers/style';

const Wrapper = styled.div`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ dir }) => dir && `flex-direction: ${dir};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ ai }) => ai && `align-items: ${ai};`}
  ${({ align }) => align && `align-self: ${align};`}
  ${({ jc }) => jc && `justify-content: ${jc};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ gap }) => gap && `gap: ${getDimention(gap)};`}
  ${({ height }) => height && `height: ${getDimention(height)};`}
`;

const Flex = ({
  flex,
  dir = 'row',
  wrap = 'wrap',
  ai = 'center',
  jc = 'center',
  width,
  as,
  children,
  gap,
  className,
  height,
  ...props
}) => {
  const componentProps = {
    flex,
    dir,
    wrap,
    ai,
    gap,
    align: as,
    jc,
    width,
    height,
    className,
    ...props
  };

  return <Wrapper {...componentProps}>{children}</Wrapper>;
};

export default Flex;
