import React from 'react';

import styled from 'styled-components';
import { colors } from 'helpers/colors';

const Wrapper = styled.div`
  border-radius: inherit;
  height: 60px;
  overflow: hidden;
  margin-bottom: -52px;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
  }
`;

const ProgressComponent = styled.div`
  background-color: ${colors.primary[50]};
  width: 100%;
  min-width: 100%;
  height: 8px;
  @media (max-width: 768px) {
    border-radius: 4px;
  }
`;

const Scale = styled.div`
  width: ${({ width }) => `${width}%`};
  min-width: ${({ width }) => `${width}%`};
  height: 100%;
  background-color: ${colors.primary[500]};
  @media (max-width: 768px) {
    border-radius: inherit;
  }
`;

const Progress = ({ value }) => {
  return (
    <Wrapper>
      <ProgressComponent>
        <Scale width={value} />
      </ProgressComponent>
    </Wrapper>
  );
};

export default Progress;
