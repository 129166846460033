const bytesInOneMB = 1024 * 1024;

const FileHelpers = {
  bytesToKB(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    const bytesInOneKB = 1024;
    return parseFloat((blobSizeBytes / bytesInOneKB).toFixed(1));
  },

  bytesToMB(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    return parseFloat((blobSizeBytes / bytesInOneMB).toFixed(1));
  },

  bytesLabel(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    if (blobSizeBytes > bytesInOneMB) return `${FileHelpers.bytesToMB(blobSizeBytes)} Mb`;
    return `${FileHelpers.bytesToKB(blobSizeBytes)} Kb`;
  },

  async triggerDownload(fileUrl, _fileName) {
    const fileName = _fileName || fileUrl.split('/').at(-1);
    const anchor = document.createElement('a');

    anchor.target = '_blank';
    anchor.href = fileUrl;
    anchor.download = fileName;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};

export default FileHelpers;
