import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';

import Settings from 'helpers/settings';
import SCStore from './reducers/index';
import rootSaga from './sagas';
import { migrations } from './migrations';

const logger = createLogger({
  collapsed: true,
  diff: true
});

const configureStore = () => {
  const middlewares = [];

  const persistConfig = {
    key: 'SCStore',
    version: 2,
    migrate: createMigrate(migrations, { debug: false }),
    storage,
    blacklist: [
      'form',
      'jobsV2',
      'usersV2',
      'applicationsV2',
      'sharedImagesV2',
      'sharedVideosV2',
      'sharedFilesV2',
      'sharedLinksV2'
    ]
  };

  const persistedReducer = persistReducer(persistConfig, SCStore);

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();
  middlewares.push(thunkMiddleware);
  middlewares.push(sagaMiddleware);

  if (Settings.LOGGING) {
    middlewares.push(logger);
  }

  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};

export default configureStore();
