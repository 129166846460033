import React from 'react';

class OutlinedButton extends React.Component {
  getStyles = () => {
    if (this.props.type === 'solid_white') {
      var backgroundColor = 'white';
      var borderColor = 'white';
      var color = 'black';
    }
    if (this.props.type === 'hollow_gray') {
      var backgroundColor = 'transparent';
      var borderColor = '#555';
      var color = '#555';
    } else {
      var backgroundColor = 'black';
      var borderColor = 'black';
      var color = 'white';
    }
    return {
      button: {
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight:
          this.props.size === 'medium'
            ? '40px'
            : this.props.size === 'large'
            ? '60px'
            : '30px',
        backgroundColor: backgroundColor,
        border: '1px solid',
        borderColor: borderColor,
        fontFamily: 'Avenir-Light',
        fontSize:
          this.props.size === 'medium'
            ? '13px'
            : this.props.size === 'large'
            ? '15px'
            : '12px',
        letterSpacing: '2px',
        height:
          this.props.size === 'medium'
            ? '40px'
            : this.props.size === 'large'
            ? '60px'
            : '30px',
        width: '100%',
        minWidth: '150px',
        color: color,
        outline: 'none',
        // border: 'none',
        padding: '0px'
      }
    };
  };

  onClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    var styles = this.getStyles();
    return (
      <button
        {...this.props}
        style={
          this.props.style
            ? Object.assign(styles.button, this.props.style)
            : styles.button
        }
        type="submit"
        onClick={this.onClick}
      >
        {this.props.title}
      </button>
    );
  }
}

export default OutlinedButton;
