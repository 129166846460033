import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import { styleDimensions } from 'helpers/style';
import ApplicationHelper from 'helpers/application_helper';
import HowItWorksModal from 'components/modals/v2/how_it_works_modal';
import SignUpByEmailModalV3 from 'components/modals/v2/auth/sign_up_by_email_modal';
import SignUpModalV3 from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3';
import SignInModal from 'components/modals/v2/auth/sign_in_modal/sign_in_modal_v2';
import SignInByEmailModal from 'components/modals/v2/auth/sign_in_by_email_modal';
import SignUpAdditionalDetailsModal from 'components/modals/v2/auth/sign_up_additional_details_modal';
import SignUpLogoModal from 'components/modals/v2/auth/sign_up_logo_modal';
import ResetPasswordModal from 'components/modals/v2/auth/reset_password_modal/reset_password_modal';
import ProjectTosModal from 'components/modals/v2/project_tos_modal/project_tos_modal';
import ChatParticipantActionsModal from 'components/modals/v2/chat_participant_actions_modal';
import ShareReferralModal from 'components/modals/v2/share_referral_modal';
import CreateBankAccountModal from './create_bank_account_modal';
import JobRateEdit from './job_rate_edit_modal/job_rate_edit_modal';
import { RootContext } from 'contexts/contexts';
import AuthHelper from 'helpers/auth_helper';
import SwitchRateModal from './switch_rate_modal';
import InfoModal from './info_modal';
import ConfirmModal from './confirm_modal';
import PaymentSelectionModal from './payment/payment_selection_modal';
import ConfirmBookingModal from './payment/confirm_boking_modal';
import AddCardModal from './payment/add_card_modal';
import InvoiceModal from './payment/invoice_modal';
import RetryPaymentModal from './payment/retry_payment_modal';
import ShareProjectModal from './share_project_modal';
import TalentTosModal from './talent_tos_modal';
import JobOptionsModal from './job_options_modal';
import JobManagementFaqModal from './job_management_faq_modal';
import HelpCenterModal from './help_center_modal';
import SignUpSuccessModal from 'components/modals/v2/auth/sign_up_success_modal';
import DatePickerModal from 'components/modals/v2/date_picker_modal/date_picker_modal';
import ShareOfferModal from 'components/modals/v2/share_offer_modal/share_offer_modal';
import SharePortfolioModal from 'components/modals/v2/share_portfolio/share_portfolio_modal';
import ContactUnavailableModal from 'components/modals/v2/contact_unavailable_modal';
import LinkInBioOnboardingModal from 'components/modals/v2/lib_onboarding_modal';
import ChatSharedModal from 'components/modals/v2/chat_shared_modal/chat_shared_modal';
import AlbumPreviewModal from 'components/modals/v2/album_preview_modal/album_preview_modal';

const ContentWrapper = styled.div`
  z-index: 1;
  background: ${colors.light};
  position: fixed;
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 30px;
  bottom: 0;
  transition: opacity 0.7s;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  @media (min-width: 769px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    //inset: 0;
    //overflow: auto;
  }
`;

const OverlayWrapper = styled.div`
  overflow: ${({ manualOverflow, isOpen }) => manualOverflow || (isOpen ? 'hidden' : 'auto')};
  opacity: ${({ isOpen }) => (isOpen ? 0.6 : 1)};
  ${({ isOpen }) =>
    isOpen &&
    css`
      filter: blur(2px);
      pointer-events: none;
    `}
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;

  @media (max-width: 768px) {
    min-height: 100%;
    height: 100%;
    padding-bottom: ${({ rootContext }) =>
      ApplicationHelper.isGuestOnboarding(rootContext.mode) || AuthHelper.isGuest()
        ? 0
        : styleDimensions.mobileBarHeight};
    position: initial;
  }
`;

export const modalEnum = {
  howItWorks: 'howItWorks',
  resetPassword: 'resetPassword',
  signUp: 'signUp',
  signIn: 'signIn',
  signInByEmail: 'signInByEmail',
  signUpByEmail: 'signUpByEmail',
  signUpAdditionalDetails: 'signUpAdditionalDetails',
  signUpLogo: 'signUpLogo',
  shareReferral: 'shareReferral',
  createBankAccount: 'createBankAccount',
  projectTOS: 'projectTOS',
  chatParticipantActions: 'chatParticipantActions',
  createProjectRate: 'createProjectRate',
  switchRate: 'switchRate',
  info: 'info',
  confirm: 'confirm',
  paymentSelection: 'paymentSelection',
  confirmBooking: 'confirmBooking',
  addCard: 'addCard',
  invoice: 'invoice',
  retryPayment: 'retryPayment',
  shareProject: 'shareProject',
  talentTOS: 'talentTOS',
  jobOptions: 'jobOptions',
  jobManagementFAQ: 'jobManagementFAQ',
  helpCenter: 'helpCenter',
  datePicker: 'datePicker',
  signUpSuccess: 'signUpSuccess',
  shareOffer: 'shareBookingOffer',
  sharePortfolio: 'sharePortfolio',
  contactUnavailable: 'contactUnavailable',
  linkInBioOnboarding: 'linkInBioOnboarding',
  chatSharedFiles: 'chatSharedFiles',
  albumPreview: 'albumPreview'
};

const Modal = ({ children, content: contentKey, onClose, modalProps, manualOverflow = null }) => {
  // prettier-ignore
  const modalContentMapping = useMemo(() => [
    { key: modalEnum.howItWorks, component: HowItWorksModal },
    { key: modalEnum.resetPassword, component: ResetPasswordModal },
    { key: modalEnum.signUpByEmail, component: SignUpByEmailModalV3 },
    { key: modalEnum.signIn, component: SignInModal },
    { key: modalEnum.signUp, component: SignUpModalV3 },
    { key: modalEnum.signInByEmail, component: SignInByEmailModal },
    { key: modalEnum.signUpLogo, component: SignUpLogoModal },
    { key: modalEnum.shareReferral, component: ShareReferralModal },
    { key: modalEnum.createBankAccount, component: CreateBankAccountModal },
    { key: modalEnum.projectTOS, component: ProjectTosModal },
    { key: modalEnum.chatParticipantActions, component: ChatParticipantActionsModal, disabled: !ApplicationHelper.isMobile },
    { key: modalEnum.signUpAdditionalDetails, component: SignUpAdditionalDetailsModal },
    { key: modalEnum.createProjectRate, component: JobRateEdit },
    { key: modalEnum.switchRate, component: SwitchRateModal },
    { key: modalEnum.info, component: InfoModal },
    { key: modalEnum.confirm, component: ConfirmModal },
    { key: modalEnum.paymentSelection, component: PaymentSelectionModal },
    { key: modalEnum.confirmBooking, component: ConfirmBookingModal },
    { key: modalEnum.addCard, component: AddCardModal },
    { key: modalEnum.invoice, component: InvoiceModal },
    { key: modalEnum.retryPayment, component: RetryPaymentModal },
    { key: modalEnum.shareProject, component: ShareProjectModal },
    { key: modalEnum.talentTOS, component: TalentTosModal },
    { key: modalEnum.jobOptions, component: JobOptionsModal },
    { key: modalEnum.jobManagementFAQ, component: JobManagementFaqModal },
    { key: modalEnum.helpCenter, component: HelpCenterModal },
    { key: modalEnum.signUpSuccess, component: SignUpSuccessModal, disabled: !ApplicationHelper.isMobile },
    { key: modalEnum.datePicker, component: DatePickerModal },
    { key: modalEnum.shareOffer, component: ShareOfferModal },
    { key: modalEnum.sharePortfolio, component: SharePortfolioModal },// Link-in-Bio
    { key: modalEnum.contactUnavailable, component: ContactUnavailableModal },
    { key: modalEnum.linkInBioOnboarding, component: LinkInBioOnboardingModal },
    { key: modalEnum.chatSharedFiles, component: ChatSharedModal },
    { key: modalEnum.albumPreview, component: AlbumPreviewModal },
  ], [])

  const getModalContent = key => modalContentMapping.find(m => m.key === key);

  const rootContext = useContext(RootContext);
  const content = getModalContent(contentKey);
  const Component = content && !content.disabled ? content.component : null;
  const isOpen = !!Component;

  return (
    <>
      <OverlayWrapper manualOverflow={manualOverflow} rootContext={rootContext} id="modal__overlay" isOpen={isOpen}>
        {children}
      </OverlayWrapper>

      <ContentWrapper id="modal__content" isOpen={isOpen}>
        {isOpen ? <Component onClose={onClose} modalProps={modalProps} /> : null}
      </ContentWrapper>
    </>
  );
};

export default Modal;
